import { combineReducers, createStore } from "redux";
import marketReducer from "./market-reducer";
import newsReducer from "./news-reducer";
import popupReducer from "./popup-reducer";
import portfolioReducer from './portfolio-reducer'




const reducers = combineReducers({
    portfolio:portfolioReducer,
    news:newsReducer,
    popup:popupReducer,
    market:marketReducer,
})

const store = createStore(reducers)

window.store = store

export default store