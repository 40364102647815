


const initialState = {
    portfolio:[

    ]

}




const portfolioReducer = (state = initialState,action) => {
    switch(action.type){
        default:
            return state
    }
}


export default portfolioReducer