import React, { Suspense } from 'react';
import { Helmet } from "react-helmet";
import AnimatedVid from './animatedVid/AnimatedVid';
import HomeNews from './news/HomeNews';
import Section from './section/Section';
const Article = React.lazy(() => import('./article/Article'))


function Home() {
    return (
        <>
            <Helmet>
                <title>Hayq Art</title>
                <meta name="description" content="Hayq Games is a leading mobile game platform technology company and development team." />
                <link rel="canonical" href="http://hayqgames.com/" />
            </Helmet>
            <AnimatedVid />
            <Suspense fallback={<div>Loading...</div>}>
                <Article />
            </Suspense>
            <HomeNews />
            <Section />
        </>
    )
}

export default Home
