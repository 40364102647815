import React from 'react';
import './Footer.scss';
import { NavLink, Link } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FiTwitch } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { BsChevronDoubleUp } from 'react-icons/bs';
import { IoMailOpenOutline } from 'react-icons/io5';
import { BsTelephoneInbound } from 'react-icons/bs';
import { AiOutlineLinkedin } from 'react-icons/ai';
import logo from '../../assets/img/logo.png'
import apple from '../../assets/img/apple.png'
import google from '../../assets/img/google.png'
import fax_icon from '../../assets/img/fax_icon.png'
import kub from '../../assets/img/kub.png'
import cgt from '../../assets/img/cgt.png'
import unity from '../../assets/img/unity.png'

function Footer() {

    const Mailto = ({ email, subject = '', body = '', children }) => {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a className="footer__link" href={`mailto:${email}${params}`}>{children}</a>;
    };

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__top">
                    <div className="footer__top-flex">
                        <div className="footer__icons">
                            <a href="https://twitter.com/GamesHayq" target="/blank"><FaTwitter className="white" /></a>
                            <a href="https://www.linkedin.com/company/hayq-games-studio/about/" target="/blank"><AiOutlineLinkedin className="white" /></a>
                            <a href="https://www.facebook.com/hayqgames" target="/blank"><FaFacebookSquare className="white" /></a>
                            <a href="https://www.twitch.tv/hayqgames" target="/blank"><FiTwitch className="white" /></a>
                            <a href="https://www.instagram.com/hayqart_studio/" target="/blank"><FaInstagram className="white" /></a>
                            <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="white" /></a>
                        </div>


                        <div onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                        }} className="isShowBtn isShowBtn_hide">
                            <p className="footer__i"><BsChevronDoubleUp className="footer__i-icon" /> BACK TO TOP</p>
                        </div>
                    </div>

                    <nav className="footer__nav">

                        {/* first */}
                        <ul className="footer__list">GENERAL
                            <li>
                                <NavLink className="footer__link" to={{ pathname: "/portfolio" }} exact>Our Arts</NavLink>
                            </li>
                            <li>
                                <NavLink className="footer__link" to={{ pathname: "/news" }} exact>Updates</NavLink>
                            </li>

                        </ul>


                        {/* second */}
                        <ul className="footer__list">CONTACT US
                            <li className="footer__list-flex">
                                <IoMailOpenOutline className="footer__li-icon" />
                                <Mailto email="info@hayqinc.com" subject="Hello & Welcome" body="Hello world!">
                                    contact@hayqart.com
                                </Mailto>
                            </li>
                            <li className="footer__list-flex">
                                <BsTelephoneInbound className="footer__li-icon" />
                                <a className="footer__link" href="tel:+18189461212">+1 (424) 666-0414</a>
                            </li>
                            <li className="footer__list-flex">
                                <img src={fax_icon} className='fax_icon_image' />
                                <a className="footer__link" href="tel:+18189461212">+1 (424) 258-3366</a>
                            </li>
                        </ul>

                        {/* fifth */}
                        <div className='footer_margin'>
                            <ul className="footer__list">
                            CHECK OUT OUR ARTS
                                <li>
                                    
                                    <a href="https://assetstore.unity.com/publishers/64608 " target="_blank" exact><img className="footer__downloads" src={unity} alt="" /></a>
                                    <a href="https://www.cgtrader.com/hayqart" target="_blank" exact><img className="footer__downloads" src={cgt} alt="" /></a>
                                    <a href="https://sketchfab.com/hayqart" target="_blank" exact><img className="footer__downloads" src={kub} alt="" /></a>
                                </li>
                            </ul>
                        </div>

                    </nav>
                </div>


                <div className="footer__bottom">
                    <p>© 2017-2022, Hayq Art, Inc. All rights reserved. We are building up a fun-loving team of the best mobile game experts to entertain the world.</p>


                    <div className="footer__bottom-flex">
                        <div className="footer__bottom-card">
                            <Link className="footer__bottom-links" to={{ pathname: "/terms" }}>Terms of service</Link>
                            <Link className="footer__bottom-links" to={{ pathname: "/privacy" }}>Privacy policy</Link>
                        </div>

                        <Link to={{ pathname: "/" }} ><img className="footer__bottom-logo" src={logo} alt="HAYQ Games" /></Link>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer
