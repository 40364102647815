import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import './Header.scss';
import logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { Context } from '../../index';
import { useDispatch, useSelector } from 'react-redux';
import { popapAc } from '../../store/popup-reducer';
import cart from '../../assets/img/cart.svg'
import {useCookies} from 'react-cookie'



function Header(props) {
    
    const { pathname } = useLocation();
    let [header, setHeader] = useState(false);
    let [burgerI, setBurger] = useState('close');
    let [openBurger, setOpenBurger] = useState(false);
    const [cookies] = useCookies(['auth','userImage'])
    
    const popup = useSelector(state => state.popup)
    const dispatch = useDispatch()


    let addSticky = () => {
        let scrollvalue = document.documentElement.scrollTop;
        if (scrollvalue >= 505) {
            setHeader(true);
        }
        else {
            setHeader(false);
        }
    }
    window.addEventListener('scroll', addSticky);

    let handleClick = () => {
        setBurger(burgerI === 'open' ? 'close' : 'open');
        setOpenBurger(!openBurger)
    }

    useEffect(() => {
        setOpenBurger(false);
        setBurger('close')
    }, [pathname]);

    return (
        <div className={header ? "sticky" : "header__main"}>
            <div className="container">
                <header className="header">
                    <nav className="header__nav">
                        <NavLink to={{ pathname: "/" }} exact className="header__logo-title"><img className="header__logo" src={logo} alt="HAYQ Games" />HAYQ Art</NavLink>

                        <ul className={`header__list ${openBurger ? 'header__list-active' : ''}`}>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/' }} exact >Home</NavLink>
                            </li>
                            {/* <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/marketplace' }} exact >Marketplace</NavLink>
                            </li> */}
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/portfolio' }} exact>Portfolio</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/updates' }} exact>Updates</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/about-us' }} exact>About Us</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/contacts' }} exact>Contacts</NavLink>
                            </li>
                            {/* <li>
                                {cookies.auth === "true" 
                                ?
                                <div className='header__link' onClick={() => {
                                    dispatch(popapAc(true))
                                }}>Logout</div>
                                :
                                <div className='header__link' onClick={() => {
                                    dispatch(popapAc(true))
                                }}>Login</div>
                                }
                                
                            </li> */}
                        </ul>




                            {cookies.auth === "true" 
                            ?
                                <div className="header__rightUser">
                                    {/* <div className='userInfo'>
                                        <NavLink to={{pathname: '/cart'}} className="cartImageNav">
                                        <img  src={cart} className='cartImage' />
                                        </NavLink>
                                        <img src={cookies.userImage} className='userImage' />
                                    </div> */}
                                    <div className="header__burger" role="button" onClick={handleClick}>
                                        <i className={burgerI}></i>
                                        <i className={burgerI}></i>
                                        <i className={burgerI}></i>
                                    </div>
                                </div>
                            :
                            <div className='header__right'>
                                <div>
                                    <div className="footer__icons header__icons">
                                        <a href="https://twitter.com/GamesHayq" target="/blank"><FaTwitter className="white" /></a>
                                        <a href="https://www.linkedin.com/company/hayq-games-studio/about/" target="/blank"><AiOutlineLinkedin className="white" /></a>
                                        <a href="https://www.facebook.com/hayqgames" target="/blank"><FaFacebookSquare className="white" /></a>
                                        <a href="https://www.instagram.com/hayqart_studio/" target="/blank"><FaInstagram className="white" /></a>
                                        <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="white" /></a>
                                    </div>
                                </div>
                                <div className="header__burger" role="button" onClick={handleClick}>
                                    <i className={burgerI}></i>
                                    <i className={burgerI}></i>
                                    <i className={burgerI}></i>
                                </div>
                            </div>
                            }


                            {/* <div className='google_button'>
                                <div className='g-sign-in-button' onClick={authWithGoogle}>
                                    <div className="content-wrapper">
                                        <div className='logo-wrapper'>
                                            <img src='https://developers.google.com/identity/images/g-logo.png' />
                                        </div>
                                        <span className='text-container'>
                                    <span className='signInSpan'>Sign in with Google</span>
                                    </span>
                                    </div>
                                </div>
                            </div> */}
                    </nav>

                    {/* {openSign && <Sign closeSign={setOpenSign} />} */}
                </header>
            </div>
        </div >
    )
}

export default Header;


