import {useContext, useState} from 'react'
import { doc,updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Context} from '../../../index'
import './Edit.css'

const Edit = (props) => {
    const {db,storage} = useContext(Context) 
    const [paragraph,setParagraph] = useState(props.data.filter(item => item.id === props.id).map(item => item.paragraph))
    const [name,setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.name))
    const [image,setImage] = useState(null)
    const [image2,setImage2] = useState([])
    const [imageChanged,setImageChanged] = useState(false)
    const [imageUrl,setImageUrl] = useState(null)
    const [imageUrl2,setImageUrl2] = useState([])
    const [imageUploaded1,setImageUploaded1] = useState(false)
    const [imageUploaded2,setImageUploaded2] = useState(false)
    const [category,setCategory] = useState(props.data.filter(item => item.id === props.id).map(item => item.type))
    const [url,setUrl] = useState(props.data.filter(item => item.id === props.id).map(item => item.path))
    const [main,setMain] = useState(props.data.filter(item => item.id === props.id).map(item => item.mainTitle))
    const [paragraphChanged,setParagraphChanged] = useState(false)
    const [nameChanged,setNameChanged] = useState(false)
    const [categoryChanged,setCategoryChanged] = useState(false)
    const [urlChanged,setUrlChanged] = useState(false)
    const [mainChanged,setMainChanged] = useState(false)
    const [modelDescription,setModelDescription] = useState(props.data.filter(item => item.id === props.id).map(item => item.modelDescription))
    const [modelDescriptionChanged,setModelDescriptionChanged] = useState('')
    const [price,setPrice] = useState(props.data.filter(item => item.id === props.id).map(item => item.price))
    const [priceChanged,setPriceChanged] = useState('')
    const [details,setDetails] = useState(props.data.filter(item => item.id === props.id).map(item => item.details))
    const [detailsChanged,setDetailsChanged] = useState(false)

    console.log(props)

    const saveEdit = () => {
        
        if (image) {
            const storageRef = ref(storage, '/images/' + image.name)
            uploadBytes(storageRef, image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageRef,image.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }


        if(paragraph !== '' && paragraphChanged === true){
            updateDoc(doc(db,'market', props.id), {
                paragraph: paragraph
            })
        }
        if(main !== '' && mainChanged === true){
            updateDoc(doc(db,'market', props.id), {
                mainTitle: main
            })
        }
        if(name !== '' && nameChanged === true ){
            updateDoc(doc(db,'market', props.id), {
                name: name,
            })
        }
        if(price !== '' && priceChanged === true ){
            updateDoc(doc(db,'market', props.id), {
                price: price,
            })
        }
        if(details !== '' && detailsChanged === true ){
            updateDoc(doc(db,'market', props.id), {
                details: details,
            })
        }
        if(modelDescription !== '' && modelDescriptionChanged === true ){
            updateDoc(doc(db,'market', props.id), {
                modelDescription: modelDescription,
            })
        }
        if(imageUrl !== null){
            updateDoc(doc(db,'market', props.id), {
                img:imageUrl,
            })
            setImageUploaded1(true)
        }else{
            setImageUploaded1(false)
        }

        if (image2 !== []) {
            image2.map(image => {
                const storageRef2 = ref(storage, '/images/' + image.name)
                uploadBytes(storageRef2, image).then(response => {
                    console.log('file2 uploaded')
                })
                getDownloadURL(storageRef2,image.name)
                    .then(urls => {
                        setImageUrl2((prevState) => [...prevState, urls])
                        console.log('file 2 url -' + imageUrl2)
                    })
            })

        }


        if(imageUrl2 !== []  && imageChanged === true){
            updateDoc(doc(db,'market', props.id), {
                image2:imageUrl2
            })
            setImageUploaded2(true)
        }else{
            setImageUploaded2(false)
        }

        console.log(props.id, 'edited')
        if(category != '' && categoryChanged === true){
            updateDoc(doc(db,'market', props.id), {
                type:category
            })
        }
        if(url !== '' && urlChanged === true){
            updateDoc(doc(db,'market', props.id), {
                path:url
            })
        }

    }
    console.log(imageUrl2)

    const takeFiles = (e) => {
        for(let i = 0;i < e.target.files.length;i++){
            const newImage = e.target.files[i]
            newImage["id"] = Math.random()
            setImage2((prevState) =>[...prevState, newImage])
            console.log(image2)
            setImageChanged(true)
        }

        
    }


    return (
        <div className=''>
            <div className='flex_column'>
                <input value={url} className='edit_input' placeholder='Edit URL' onChange={(e) => {
                    setUrl(e.target.value)
                    setUrlChanged(true)
                }} />
                <input value={main} className='edit_input' placeholder='Edit main text' onChange={(e) => {
                    setMain(e.target.value)
                    setMainChanged(true)
                }} />
                <input value={name} className='edit_input' placeholder='Edit Title' onChange={(e) => {
                    setName(e.target.value)
                    setNameChanged(true)
                }} />
                <input value={price} className='edit_input' placeholder='Edit Price' onChange={(e) => {
                    setPrice(e.target.value)
                    setPriceChanged(true)
                }} />
                <input value={modelDescription} className='edit_input' placeholder='Edit Model Description' onChange={(e) => {
                    setModelDescription(e.target.value)
                    setModelDescriptionChanged(true)
                }} />
                <textarea value={paragraph} placeholder='Edit Description' className='second_input' onChange={(e) => {
                    setParagraph(e.target.value)
                    setParagraphChanged(true)
                }} rows={"5"} cols="67" ></textarea>
                <textarea value={details} placeholder='Edit Technical Description' className='second_input' onChange={(e) => {
                    setDetails(e.target.value)
                    setDetailsChanged(true)
                }} rows={"5"} cols="67" ></textarea>
                <label className='color_white'>Main Picture</label>
                <input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                {imageUploaded1 
                ?
                    <span className='image_uploaded_text'>Image succsesfully uploaded</span> 
                :   
                    <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                }
                <label className='color_white'>Article pictures (1200 x 800)</label>
                <input type={'file'} onChange={takeFiles} multiple />
                
                {imageUploaded2 
                ?
                    <span className='image_uploaded_text'>Image succsesfully uploaded</span> 
                :   
                    <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                }
                <div className='select_category'>
                    <select className='' value={category} onChange={e => {
                        setCategory(e.target.value)
                        setCategoryChanged(true)
                    }}>
                        <option value={'lp'} onChange={e => setCategory(e.target.value)}>2D</option>
                        <option value={'rig'} onChange={e => setCategory(e.target.value)}>3D</option>
                        <option value={'anim'} onChange={e => setCategory(e.target.value)}>Grapich design</option>
                        <option value={'texture'} onChange={e => setCategory(e.target.value)}>Grapich design</option>
                    </select>
                </div>
                <button className='saveButton' onClick={saveEdit}>Save</button>
            </div>
        </div>
    )
}


export default Edit