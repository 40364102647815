import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './Ps.scss';


function Ps() {



    return (
        <div className="ourGames">
            <Helmet>
                <title>2D Art</title>
                <meta name="description" content="A list is full of the best and with the most downloads mobile games to play. Join Us!" />
                <link rel="canonical" href="http://hayqgames.com/ps4-ps5-xbox" />
            </Helmet>
            <div className="container">
                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">2D Art</h2>

                <p className="ourGames__p" data-aos="zoom-in-up" data-aos-duration="3000">
                    2D animation is a style of animation that uses two-dimensional images to create the illusion of motion. This type of animation has been around for centuries, and it's still used today in many popular cartoons and movies. While 3D animation is becoming more popular, 2D animation still has its advantages. Some of the advantages of 2D over 3D are its lower production costs and the ability to evoke a wide range of emotions in the viewer that can't be conveyed with 3D graphics alone. 2D modeling has become an increasingly popular way to create digital art. While it may seem simple at first, there is a lot of nuance involved in creating high-quality 2D art. Our team of skilled 2D specialists can create beautiful and engaging animations that will capture your audience's attention.
                </p>

                
            </div>
        </div>
    )
}

export default Ps
