import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from './components/preloader/Preloader';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Header from './components/header/Header';
import Home from './components/pages/home/Home';
import Pc from './components/pages/platforms/pc/Pc';
import MobileGames from './components/pages/platforms/mobileGames/MobileGames';
import Ps from './components/pages/platforms/ps/Ps';
import Team from './components/pages/team/Team';
import News from './components/pages/news/News'
import Contacts from './components/pages/contacts/Contacts';

import Footer from './components/footer/Footer';
import Terms from './components/pages/terms/Terms';
import Privacy from './components/pages/privacy/Privacy';
import Admin from './components/admin/Admin';
import {Context} from './index'
import { useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useState } from 'react';
import Portfolio from './components/pages/portfoloio/Portfolio';
import PortfolioId from './components/pages/portfoloio/PortfolioId';
import AdminNews from './components/admin_news/AdminNews';
import GamePage from './components/pages/news/newsPages/GamePage';
import LoginPopup from './components/LoginPopup/LoginPopup';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Marketplace from './components/pages/marketplace/Marketplace';
import Marketplaceid from './components/pages/marketplace/Marketplaceid/Marketplaceid';
import MarketAdmin from './components/admin/MarketAdmin/MarketAdmin';
import Cart from './components/pages/cart/Cart';
import ReviewPopup from './components/ReviewPopup/ReviewPopup';
import Cookies from './components/cookies/Cookies';

function App() {
  const {db,store} = useContext(Context)
  const portfolio = collection(db,'portfolio')
  const news = collection(db,'blog')
  const market = collection(db,'market')
  const portfolioStore = store.getState().portfolio.portfolio
  const newsStore = store.getState().news.news
  const marketStore = store.getState().market.market
  const [portfolioState,setPortfolioState] = useState(null)
  const [newsState,setNewsState] = useState(null)
  const [marketState,setMarketState] = useState(null)
  const popup = useSelector(state => state.popup.popup)
  const reviewPopup = useSelector(state => state.popup.popup2)
  const [cookies,setCookies] = useCookies(['auth','uid'])
  const [userId,setUserId] = useState(null)
  console.log(popup)
  // const [comingGames,setComingGames] = useState(null)

  useEffect(() => {
    Aos.init();
    getDocs(portfolio).then(response => {
      response.docs.map(doc => {
        setPortfolioState([...portfolioStore, portfolioStore.unshift(doc.data())])
      })
    })
    getDocs(market).then(response => {
      response.docs.map(doc => {
        setMarketState([...marketStore, marketStore.unshift(doc.data())])
      })
    })
    getDocs(news).then(response => {
      response.docs.map(doc => {
        setNewsState([...newsStore, newsStore.unshift(doc.data())])
      })
    })
    
    if(!localStorage.getItem('cart')){
      var a = [];
      a.push(JSON.parse(localStorage.getItem('cart')));
      localStorage.setItem('cart', JSON.stringify(a));
    }
    if(!localStorage.getItem('useCookies')){
      localStorage.setItem('useCookies', JSON.stringify(false))

    }

    // getDocs(comingSoon).then(response => {
    //   response.docs.map(doc => {
    //     setComingGames([...gamesStore, gamesStore.unshift(doc.data())])
    //   })
    // })

    document.onkeydown = function(event) {
      if (event.ctrlKey && (event.keyCode == 85) || (event.ctrlKey && event.shiftKey && (event.keyCode == 73)) || event.keyCode == 123) {
          return false
      }
    }
    document.oncontextmenu = cmenu; function cmenu() { return false; }
    document.onmouseenter = photo; function photo(){return false}



  }, [])
  

  return (
    <>
      <Router>
        <Preloader />
        {popup === true
        ?
        <LoginPopup />
        :
        null
        }
        {reviewPopup === true 
        ?
         <ReviewPopup />
        :
        null
        }
        
        <Cookies />
    
        
        
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />
          {/* <Route exact path='/marketplace' component={Marketplace} /> */}
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/portfolio/:id' component={PortfolioId} />
          {/* <Route exact path='/marketplace/:id' component={Marketplaceid} /> */}
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/market' component={MarketAdmin} />
          <Route exact path='/admin/news' component={AdminNews} />
          <Route exact path='/2d' component={Ps} />
          <Route exact path='/animation' component={MobileGames} />
          <Route exact path='/3d' component={Pc} />
          <Route exact path='/about-us' component={Team} />
          <Route exact path='/updates' component={News} />
          <Route exact path='/updates/:link' component={GamePage} />
          <Route exact path='/contacts' component={Contacts} />
          {/* <Route exact path='/cart' component={Cart} /> */}

          <Route exact path='/terms' component={Terms} />
          <Route exact path='/privacy' component={Privacy} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
export default App;
