import { Helmet } from "react-helmet";
import './PortfolioPages.scss';
import './PortfolioId.css'
import { useContext, useEffect, useRef, useState } from "react";
import { sanitize } from 'dompurify'
import { Context } from "../../../index";
import arrowDown from '../../../assets/img/arrow_left.png'


const PortfolioId = (props) => {
    const { store } = useContext(Context)
    const pageId = props.match.params.id
    const [dataUri, setDataUri] = useState('')
    const portfolioData = store.getState().portfolio.portfolio
    const [currentPhoto, setCurrentPhoto] = useState('')
    const [currentIndex,setCurrentIndex] = useState(0)
    const [startIndex,setStartIndex] = useState(0)
    const [lastIndex,setLastIndex] = useState(3)
    console.log(lastIndex)
    
    useEffect(() => {
        const allElems = document.querySelectorAll('#mini_images_id')
        

        for (var i = 0; i < allElems.length; i++) {
            allElems[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active_element");
                current[0].className = current[0].className.replace(" active_element", "");
                this.className += " active_element";
            });
        }
    }, [])



 
    return (
        <>
            {portfolioData.filter(id => id.path === pageId).map(item =>
                <>
                    <Helmet>
                        <title>{item.mainTitle} - Hayq Art</title>
                        <link rel="canonical" href="http://eliteglobesolutions.com/portfolio/a-to-z-hvac" />
                        <meta name="description" content={`${item.mainTitle} - Eliteglobesolutions`} />
                    </Helmet>
                    
                    <div className="main">
                        <div className="main_flex">
                            <div className="first_flex">
                                <div className="first_section_portfolio">
                                    {item.image2.length > 5
                                    ?
                                    <>
                                        {startIndex > 0 
                                        ? 
                                            <div className="arrow_top_portfolio">
                                            <img src={arrowDown} onClick={() => {
                                                setStartIndex(startIndex - 3)
                                                setLastIndex(lastIndex - 3)
                                            }} />
                                            </div>
                                        :
                                            null
                                        }
                                        {item.image2.slice(startIndex,lastIndex).map((img,index) => (
                                            <>
                                                <div className="mini_images" key={index}>
                                                    <img src={img} draggable="false" id="mini_images_id" onContextMenu={() => {
                                                        return false
                                                    }}  onClick={(e) => setCurrentPhoto(e.currentTarget.src)} className={`${item.image2.indexOf(img) === 0 ? 'mini_images_img' + ' ' + 'active_element' : 'mini_images_img'}`} />
                                                </div>
                                            </>
                                        ))}
                                        {lastIndex < (item.image2.length) 
                                        ? 
                                        <div className="arrow_down_portfolio">
                                            <img src={arrowDown} onClick={() => {
                                                setStartIndex(startIndex + 3)
                                                setLastIndex(lastIndex + 3)

                                            }} />
                                        </div>
                                        :
                                            null
                                        }

                                    </>
                                    :   
                                        <>
                                            {item.image2.map(img => (
                                            <div className="mini_images">
                                                <img src={img} draggable="false" id="mini_images_id" onContextMenu={() => {
                                                    return false
                                                }}  onClick={(e) => setCurrentPhoto(e.currentTarget.src)} className={`${item.image2.indexOf(img) === 0 ? 'mini_images_img' + ' ' + 'active_element' : 'mini_images_img'}`} />
                                            </div>
                                        ))}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="second_section">
                                <div className="main_image">
                                    <img onContextMenu={() => {return false}} draggable="false" src={currentPhoto === '' ? item.image2[0] : currentPhoto} />
                                </div>
                            </div>
                            <div className="second_section_swiper">
                                {item.image2.filter(img => item.image2.indexOf(img) === currentIndex).map(image => (
                                    <>

                                        <div className="main_image">
                                            {item.image2.indexOf(image) > 0 
                                            ?
                                                <div className="arrow_left" onClick={() => setCurrentIndex(currentIndex - 1)}>
                                                        
                                                </div>
                                            :
                                                null
                                            }

                                            <img src={image} onContextMenu={() => {return false}} draggable="false" />
                                            {item.image2.indexOf(image) < (item.image2.length -1)
                                            ?
                                                <div className="arrow_right" onClick={() => setCurrentIndex(currentIndex + 1)}>
                                                        
                                                </div>
                                            :
                                                null
                                            }
                                        </div>
                                    </>
                                ))}
                            </div>

                        </div><br />
                        <div className="third_flex_portfolio">
                                <div className="third_section">
                                    <div className="item_title_width">
                                        <h2 className="item_title" dangerouslySetInnerHTML={{__html: sanitize(item.name.replace(/(?:\r\n|\r|\n)/g, '<br>'))}}></h2>
                                        <div className="hrText"></div>
                                    </div>
                                    <span className="item_paragraph" dangerouslySetInnerHTML={{__html: sanitize(item.paragraph.replace(/(?:\r\n|\r|\n)/g, '<br>'))}}></span>
                                </div>
                        </div>                                           
                    </div>
                </>

            )}

        </>
    )
}


export default PortfolioId