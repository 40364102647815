import { useState } from 'react'
import './Cookies.css'


const Cookies = () => {

    const [allowCookied,setAllowCookie] = useState(JSON.parse(localStorage.getItem('useCookies')))

    return(
        <>
        {allowCookied == false
        ? 
        <div className='cookiesMain'>
            <div className='weUseCookies'>
                <div className='cookieTextDiv'>
                    <span>
                        This website uses cookies. We use cookies to ensure that we give you the best experience on our website to personalise content and to analyse our traffic
                    </span>
                </div>
                <div className='allowButtonDiv'>
                    <button onClick={() => {
                        localStorage.setItem('useCookies', "true")
                        setAllowCookie(true)
                    }} className='allowButton'>Allow</button>
                </div>
            </div>
        </div>
        
        :
            null
        }
        </>
    )
}

export default Cookies