const POPUP = "POPUP"
const POPUP2 = "POPUP2"
const REVIEWER = "REVIEWER"
const USEREMAIL = "USEREMAIL"

const initialState = {
    popup:false,
    popup2:false,
    id:null,
    userEmail:null

}




const popupReducer = (state = initialState,action) => {
    switch(action.type){
        case POPUP:
            return {...state,popup:action.payload}
        case POPUP2:
            return {...state,popup2:action.payload}
        case REVIEWER:
            return {...state,id:action.id}
        case USEREMAIL:
            return {...state,userEmail:action.userEmail}
        default:
            return state
    }
}

export const popapAc = (payload) => ({type:POPUP, payload})
export const popap2Ac = (payload) => ({type:POPUP2, payload})
export const reviewerAC = (id) => ({type:REVIEWER, id})
export const userEmailAC = (userEmail) => ({type:USEREMAIL, userEmail})

export default popupReducer