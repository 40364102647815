import React, { useRef } from 'react';
import { Helmet } from "react-helmet";
import './Contacts.scss';
import emailjs from 'emailjs-com';

function Contacts() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_uwjtloj', 'template_tfxx0z4', e.target, 'user_gmLXHO5qvoc7mD6ohzjQa')
            .then(() => {
                alert('Message is send');
            }, (error) => {
                alert(error.text);
            });
        e.target.reset()
    };

    return (
        <div className="contacts">
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact us and get answers to all your questions. You’ll find all the useful information here." />
                <link rel="canonical" href="http://hayqgames.com/contacts" />
            </Helmet>
            <div className="container">
                <div className="contacts__box">

                    <div className="contacts__box-title"  data-aos="zoom-in-right" data-aos-duration="3000">
                        <h2>To make requests for further information, contact us via our social channels or complete the form.</h2>
                        <p>We just need a couple of hours!</p>
                    </div>

                    <form className="contacts__form" ref={form} onSubmit={sendEmail}  data-aos="zoom-in-left" data-aos-duration="3000">
                        <div className="contacts__form-flex">
                            <div className="contacts__form-between">
                                <input type="text" name="name" placeholder="Name *" required />
                                <input type="text" name="lastname" placeholder="Last Name *" required />
                            </div>
                            <div className="contacts__form-between">
                                <input type="email" name="email" placeholder="Email *" required />
                                <input type="subject" name="subject" placeholder="Subject *" required />
                            </div>
                        </div>
                        <textarea name="message" placeholder="Please describe what you need." required />
                        <button className="btn-center" type="submit">Send</button>
                    </form>

                </div>
            </div>

            <div className="contacts__map container"  data-aos="zoom-in" data-aos-duration="3000">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3301.1132102239285!2d-118.3136117!3d34.1690231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bfe354167e53%3A0xddfa8364c46246dd!2s621%20S%20Victory%20Blvd%20%233%2C%20Burbank%2C%20CA%2091502%2C%20USA!5e0!3m2!1sen!2s!4v1638427710599!5m2!1sen!2s" width="600" height="450" loading="lazy" title="fdcbf"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.0522462891868!2d-117.95386338530682!3d33.83676323650312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2994e50f7805%3A0xc883b525f8926d24!2zMTk1MCBXIENvcnBvcmF0ZSBXYXksIEFuYWhlaW0sIENBIDkyODAxLCDQodCo0JA!5e0!3m2!1sru!2s!4v1642589217023!5m2!1sru!2s" width="600" height="450" loading="lazy" title="fdcbf"></iframe>
            </div>
        </div>

    )
}

export default Contacts
