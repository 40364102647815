import './ReviewPopup.css'
import logo from './../../assets/img/logo.png'
import close_button from './../../assets/img/close_button.png'
import { useCookies } from 'react-cookie';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { popap2Ac, popapAc, reviewerAC } from '../../store/popup-reducer';
import { useDispatch, useSelector, useStore } from 'react-redux';
import ReactStars from 'react-stars'
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';

const ReviewPopup = (props) => {

    const [cookies,setCookies,removeCookies] = useCookies(['uid','email','name','userImage','auth'])
    const [selectedRate,setSelectedRate] = useState(0)
    const [starWarning,setStarWarning] = useState(true)
    const [textareaWarning,setTextareaWarning] = useState(true)
    const [reviewInput,setReviewInput] = useState('')
    const [buyers,setBuyers] = useState()
    const [reviewers,setReviewers] = useState()
    const [reviewExist,setReviewExist] = useState(null)
    const {auth,db, storage, store} = useContext(Context) 
    const reviewer = useSelector(state => state.popup.id)
    const market = doc(db,'market', reviewer)
    const [cart,setCart] = useState(JSON.parse(localStorage.getItem("hayqart__cart")))
    const [buyerExist,setBuyerExist] = useState(false)
    const [textLength,setTextLength] = useState(true)
    

    
    const dispatch = useDispatch()
    const selectedValue = (value) => {
        setSelectedRate(value)
        setStarWarning(true)
    }
    
    useEffect(() => {
        getDoc(market).then(response => {
            setBuyers(response.data().buyers)
        })
    },[])
    useEffect(() => {
        getDoc(market).then(response => {
            setReviewers(response.data().reviews)
            console.log(response.data().reviews)
        })
    },[])

    console.log(reviewer)


    const addReview = () => {

        if(selectedRate !== 0 & reviewInput !== '' & reviewInput.length < 200  & buyers.some(el => {if(el.email == cookies.email){return true}}) & reviewers.every(elem => elem.email !== cookies.email)){
            updateDoc(market,{
                reviews: arrayUnion({email:cookies.email,name:cookies.name,userImage:cookies.userImage,stars:selectedRate,text:reviewInput,reviewed:true})
            }).then(() => {
                setReviewExist(true)
                dispatch(popap2Ac(false))
                
            })
            cart.filter(el => el.id === reviewer).map(elem => {elem.reviewed = true})
            console.log(cart)
            localStorage.setItem("hayqart__cart", JSON.stringify(cart))


        }else if(selectedRate === 0){
            setStarWarning(false)
        }else if(reviewInput === ''){
            setTextareaWarning(false)
        }else if(reviewInput.length > 200){
            setTextLength(false)

        }
    }


    return (
        <div className='reviewPopup'>

            <div className='popup2'>
            <div className='topPart2'>
                <div className='centerPart2'>
                    <img className='logoImg2' src={logo} alt="" />
                    <span className='logoText2'>HAYQ Art</span>
                </div>
                <img className='closeImg2' src={close_button} onClick={() => {
                    dispatch(popap2Ac(false))
                }} alt="" />
            </div>
                <div className='centerFlex2'>
                    <div className='popup2Part'>
                        <textarea className='reviewTextarea' value={reviewInput} onChange={(e) => {
                            setReviewInput(e.target.value)
                            setTextareaWarning(true)
                        }}></textarea>
                    </div>
                    {textareaWarning 
                    ?
                    null 
                    :
                    <span className='requiredTextarea'>Required field</span>
                    }
                    {textLength 
                    ?
                    null 
                    :
                    <span className='requiredTextarea'>Review length much 200 symbols</span>
                    }
                    <div className='bottomReviewPart'>
                        <ReactStars count={5} size={24} value={selectedRate} half={false} color={'#ffd700'} onChange={selectedValue}  />
                        <button onClick={addReview} className='reviewButton'>Add Review</button>
                    </div>
                    {starWarning 
                    ?
                    null 
                    :
                        <span className='requiredStars'>Required field</span>
                    }
                    {reviewExist 
                    ? 
                        <div className='reviewBlockSuccsesfull'>
                            <span className=''>You have successfully left a review</span>
                        </div>
                    :
                    null
                    }


                </div>
            </div>

            
        </div>
    )
}

export default ReviewPopup