


const initialState = {
    
    market:[
        
    ]

}




const marketReducer = (state = initialState,action) => {
    switch(action.type){
        default:
            return state
    }
}


export default marketReducer