import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../ps/Ps.scss';
import Mobilegame from '../../../../assets/img/mobilegame.png';
import Androidgame from '../../../../assets/img/androidgame.png';
import Iosgame from '../../../../assets/img/iosgame.png';
import Iosandroid from '../../../../assets/img/iosandroid.png';

function MobileGames() {

    const mobileData = [
        {
            id: 1,
            title: "Advantages of Mobile Game Development",
            text: "Tablet gaming, smartphone or other varieties serving as a platform for gaming has expanded covering vast majority of local and international market being the sphere knowing no borders of expansion. Cross-platform possibilities enhance hybrid forms with the implementation of tools and technologies ensuring speedy and efficient evolvement. IPhone game to play with friends accesses continents overcoming distances and obstacles. Regular feedback integration allows cycle adjustments serving the positive accommodations and amendments in the controlling system. Fast growing market intends implantation of cohesive products meanwhile prioritizing player’s experiences gained within time. The latter is achievable  with customized solutions scratching the domain of specialized services, integration and  production assets. Being the key aspect within the framework od social media  it necessarily needs to be integrated adding replay values not losing loyal gamers. Diversity of genres is of significance as well such as  casual, puzzle, arcade or alike.",
            img: Androidgame,
        },
        {
            id: 2,
            title: "Benefits of Android Game Development",
            text: "Today’s market is determined, imperative with  smartphone gaming features traditionally serving as driver attracting young people worldwide. Due to the availability of distinctive platforms application developers need to provide rising demand with newer suggestions. Android applications utilize various mobile devices thanks to well-settled and conveyablity often surpassing  recent-time requirements. No matter of its sizes awesome platform offers quality for smaller and larger organizations. Submission procedure are easier, achievable and are done effortlessly. Launching time and programming language have also been adjusted to the design of new games. Average programmer can guess operation system that makes  it uniformed and unlimited. Mobile phone making organizations tend to attract  extensive number of clients performing intensive actions towards it. We cannot admit the fact of it being profitable  with ground-breaking applications and enjoyable services. Gaining desires outcome has not been closer enough. During the turnaround periods  robust development turns into a cutting-edge industry of  alternative earnings. Thus, conveying ideas into technically supported dimensions leads to an easy-going business.",
            img: Mobilegame,
        },
        {
            id: 3,
            title: "Benefits of iOS Game Development",
            text: " iOS devices  provide profitable opportunities, prospects for games sometimes ignoring tendencies of new product market shuffling. ios games with controller support  ensure  excusive supremacy standing out with special  and strict  selection provoking  refusal of the games in stores since the rules for them are severe. Does this mean figuring out a special platform for particularities? Remaining true-blue to their populousness and influantaility they still earn the revenue flicking their fingers. Success of the reviews intends adherence to the existing guidelines and constant maintenance of data backups. There is no need to optimize each processor and graphic accelerator since debugging refers to the outer design only. Open-source apparatus implies unimaginable  manufacturing possibilities, no problematic  adaption is serving beyond the given parameters. Benefits of working with iOS are based on well-versed specifications. Readiness for requalification advances selection criteria visible in the company’s well-to-do  portfolio meantime it has the likelihood of certifying devices despite the complicated stipulation set-ups for users.",
            img: Iosgame,
        },
        {
            id: 4,
            title: "Mobile Games Services for iOS and Android Platform",
            text: "Two major ports of mobile applications port  in iOS and Android having systematic architecture and conquering marketing strategies. Both being multidimensional in their provided services dictate mobile traffic and rules governing the field. Which one to choose if the scale has best offline iphone games and best offline android games .Prerequisites will show not substantial results since the confrontation of the issue is mainly based on the current development of the platform. Figuring out the distinctive points we underline the fact that Android is formed with Java virtual machinery, is free to manufacture and implements entertainment purposes. Unlike IOS, it is linked to Google, whereas the first one  is developed by Apple. Predominant ecosystem is not easy to penetrate. Interest and profitability are interchangeable in both cases. Statistical research has not revealed principality of neither of them. Manufacturers compete for exceeding their market  borderlines, terms of quantity may change but functionality and quality are supposedly remaining unmodified.",
            img: Iosandroid,
        },

    ]

    return (
        <div className="ourGames">
            <Helmet>
                <title>Best new mobile games on iOS and Android</title>
                <meta name="description" content="Embrace your inner champion and compete for real prizes in your favorite iOS and Android game." />
                <link rel="canonical" href="http://hayqgames.com/ios-and-androids" />
            </Helmet>
            <div className="container">
                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">Animation</h2>

                <p className="ourGames__p" data-aos="zoom-in-up" data-aos-duration="3000">
                Animation is one of the most fascinating and unique art forms that ever existed. Animation has truly come a long way. What may seem like magic to some is actually the hard work and dedication of our animators. There are so many different styles and techniques to creating a good animation, which is why our team of animators are some of the best in the business. Our animators use their experience, skill, and creativity to produce amazing animations that amaze our audiences. Whether it's an adorable children's show or a thrilling action movie, our team can handle any project with ease.
                    Expansive administration of Hayq Games foster new diversions of experimental and pilot genres fluctuating within the rapidly changing environment. Our implications deserve their place in the market no shortage of hits or classical versions is present.
                </p>

                
            </div>
        </div>
    )
}

export default MobileGames
