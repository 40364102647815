import './LoginPopup.css'
import logo from './../../assets/img/logo.png'
import close_button from './../../assets/img/close_button.png'
import { GoogleAuthProvider, signInWithPopup ,signOut} from 'firebase/auth';
import { doc, setDoc,getDocs,collection } from 'firebase/firestore';
import { useCookies } from 'react-cookie';
import { useContext, useState } from 'react';
import { Context } from '../../index';
import { popapAc } from '../../store/popup-reducer';
import { useDispatch, useSelector } from 'react-redux';


const LoginPopup = (props) => {

    const [cookies,setCookies,removeCookies] = useCookies(['uid','email','name','userImage','auth'])
    const {auth,db, storage, store} = useContext(Context)
    const logout = useSelector(state => state.popup.popup)
    const emails = []
    const date = Date.now() 
    const dispatch = useDispatch()

    const authWithGoogle = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth,provider).then(response => {
            getDocs(collection(db, 'users')).then(res => {
                res.docs.map(el => {
                    emails.push(el.data().email)
                    if(emails.every(el => el !== response.user.email)){
                        setDoc(doc(db, 'users', date + 'id'), {
                            uid:response.user.uid,
                            email:response.user.email,
                            name:response.user.displayName,
                            userImage:response.user.photoURL
                            
                        })
                    }
                })
            })
            setCookies('uid', response.user.uid, {path: '/'})
            setCookies('email', response.user.email,{path: '/'})
            setCookies('name', response.user.displayName,{path: '/'})
            setCookies('userImage', response.user.photoURL,{path: '/'})
            setCookies('auth', true,{path: '/'})
            dispatch(popapAc(false))

        })
    }

    const googleSignOut = () => {
        signOut(auth).then(() => {
            removeCookies('auth')
            removeCookies('uid')
            removeCookies('email')
            removeCookies('name')
            removeCookies('userImage')
        })
    }

    return (
        <div className='loginPopup'>
            {cookies.auth !== "true" 
            ?
            <div className='popup'>
            <div className='topPart'>
                <div className='centerPart'>
                    <img className='logoImg' src={logo} alt="" />
                    <span className='logoText'>HAYQ Art</span>
                </div>
                <img className='closeImg' src={close_button} onClick={() => {
                    dispatch(popapAc(false))
                }} alt="" />
            </div>
                <div className='centerFlex'>
                    <div className='google_button'>
                        <div className='g-sign-in-button' onClick={authWithGoogle}>
                            <div className="content-wrapper">
                                <div className='logo-wrapper'>
                                    <img src='https://developers.google.com/identity/images/g-logo.png' />
                                </div>
                                <span className='text-container'>
                            <span className='signInSpan'>Sign in with Google</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className='popup'>
            <div className='topPart'>
                <div className='centerPart'>
                    <img className='logoImg' src={logo} alt="" />
                    <span className='logoText'>HAYQ Art</span>
                </div>
                <img className='closeImg' src={close_button} onClick={() => {
                    dispatch(popapAc(false))
                }} alt="" />
            </div>
                <div className='centerFlex'>
                    <div className='google_button'>
                        <div className='g-logout-button' onClick={googleSignOut}>
                            <div className="content-wrapper">
                                <div className='logo-wrapper'>
                                    <img src='https://developers.google.com/identity/images/g-logo.png' />
                                </div>
                                <span className='text-container'>
                            <span className='signInSpan'>Logout from Google</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            
        </div>
    )
}

export default LoginPopup