import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../ps/Ps.scss';
import './Pc.css'


function Pc() {

  

    return (
        <div className="ourGames">
            <Helmet>
                <title>3D</title>
                <meta name="description" content="You'll find the best games to play in your browser, as well as our game apps. Learn more..." />
                <link rel="canonical" href="http://hayqgames.com/pc" />
            </Helmet>
            <div className="container">
                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">3D Art</h2>


                <p className="ourGames__p" data-aos="zoom-in-up" data-aos-duration="3000">
                    When it comes to animation, most people think in terms of two dimensions – width and height. However, with the help of 3D animation tools and software, animators can create imagery that appears to jump off the screen in three glorious dimensions. This heightened level of realism can be used for a variety of purposes, from animated movies and video games to product demonstrations and corporate presentations. 3D modeling is a process of creating three-dimensional models using computer software. It can be used to design objects, characters, and landscapes. 3D modeling has become increasingly popular in recent years, thanks to the wide range of software programs available and the growing number of people with access to 3D printers.  Here at our studio, we are experts in the 3D sphere, and we use our skills to produce stunning visuals that engage and entertain audiences around the world.

                </p>


            </div>
        </div>
    )
}

export default Pc
