import React from 'react';
import './Join.scss';
import JoinGame from '../../../../assets/img/joingame.png';
import JoinGame2 from '../../../../assets/img/joingame2.png';

function Join() {
    return (
        <div className="join_background">
            <div className="container">
                <div className="join">
                    <div className="join__box">
                        <div className="join__box-title" data-aos="zoom-out-up" data-aos-duration="2000">
                            <h2 className="title">Why I <strong>joined</strong> Hayq Art</h2>
                        </div>

                        <p className="join__box-card" data-aos="zoom-in-right" data-aos-duration="3000">
                        Hayq Art is a digital art and graphic design studio. We offer 2D, 3D and graphic design services. 
                        Our team of designers has over 5 years of experience in the industry. We create beautiful, high quality designs that help our clients stand out from the competition. We are passionate about art and design, and we love working with clients to create beautiful and effective visual communications. We are dedicated to providing our clients with high-quality work that exceeds their expectations. 
                        </p>
                    </div>

                    <div className="join__box-img" data-aos="zoom-in-left" data-aos-duration="3000">
                        <img src={JoinGame} alt="" />
                    </div>
                </div>

                <div className="join">
                    <div className="join__box-img" data-aos="zoom-in-right" data-aos-duration="3000">
                        <img src={JoinGame2} alt="" />
                    </div>

                    <div className="join__box">
                        <p className="join__box-card" data-aos="zoom-in-left" data-aos-duration="3000">
                        As artists, we are constantly driven to create. Whether it's painting, sculpture or graphic design, we are always looking for new ways to express our ideas and vision. For some of us, it's a need to communicate our innermost thoughts and feelings. For others, it's simply the joy of creation. But what motivates us to continue making art? For some of us it’s the joy of creation and the satisfaction of seeing an idea come to life. Others may find purpose in using their art to communicate a message or make people feel something. Whatever our reasons for creating art, we hope we’ll continue to explore and grow as an artist.                         </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Join
