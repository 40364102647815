import React from 'react';
import './Privacy.scss';

function Privacy() {
    return (
        <div className="privacy">
            <div className="container">
                <p>THESE TERMS AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. PLEASE READ THEM CAREFULLY BEFORE USING THE SERVICES OF HAYQ ART.
                    Terms and conditions to use Hayq Art’s website and its services are set forth in following documents:
                </p>
                <p>
                    1. General Terms and Conditions
                    2. Privacy Policy
                    3. Content Policy
                    4. Refund Policy
                </p><br />
                <h2>DEFINITIONS</h2>
                <p>
                    1. Buyer – a Member, who purchases Products or downloads Products that are available free of charge from the Site;<br />
                    2. Content – any material published at the Site including but not limited to wire files, models, textures, plugins, video games, video game modifications, motion files, collections, packages, materials, scripts, shapes, custom UI skins, tutorials, frequently asked questions, words, music, films, images, software, code and any other information.<br />
                    3. Contract – an agreement between Designer and Buyer according to which the Designer promises to create the Buyer a Product for a pre-agreed fee.<br />
                    4. Designer - a Member, who acts as a taxable person and in the course of its economic activity accepts a project and enters into an agreement with the Buyer to create a Product or a Member who joins Designer Partnership area.<br />
                    5. General Terms – this General Terms and Conditions in its entirety and including all terms and (or) information, accessible via any links, provided in this document.<br />
                    6. Incorporated Product – Product that cannot be extracted from an application or other product and used as a stand-alone object without the use of reverse engineering tools or techniques. For avoidance of doubt, Incorporated Product is such use of a Product that does not allow further distribution of the Product outside of the application or product containing the Incorporated Product.<br />
                    7. Member – any person who creates a user account at the Site.<br />
                    8. Product – <br />
                    a) Any Content, uploaded by Hayq Art to the Marketplace section of the Site and thus offered by the Hayq Art to the potential Buyers for purchase or free download;<br />
                    b) Content created by the Designer for the purpose of fulfilling the obligations arising from the Contract between Designer and Buyer.<br />
                    9. Seller – a Member, who acts as a taxable person and in the course of its economic activity uploads Products to the Product section of the Site and offers them for sale or free download.<br />
                    10. Site – hayqart.com website.<br />
                    11. Content Policy – intellectual property policy of hayqart.com website, available <a href='#content_policy'>here.</a><br />
                    12. Privacy Policy – collection and process of personal data, available <a href='#privacy_policy'>here.</a><br />
                    13. Refund Policy – marketplace refund policy, available <a href='#refund_policy'>here.</a><br />
                </p><br />
                <h2>GENERAL TERMS OF USE OF THE SITE</h2>
                <h5>1. License to Use This Site</h5>
                <p>
                    1.1. This agreement will become effective upon Creating Account and will remain in effect for the duration of your Account. To register for an Account, you must connect your Google account to the Website.<br />
                    1.2. This Site is protected by copyright law and international treaty. You are allowed to use the Site for your personal, non-commercial internal use only, unless specifically licensed to do otherwise by Hayq Art or unless it is expressly indicated otherwise.
                    This right to use this Site constitutes a license, not a transfer of title, and you may not nor permit anyone else to:
                    - modify the Site or use it for any commercial purpose or public display, performance, sale or rental;
                    - de-compile, reverse engineer, or disassemble, modify the Site, create derivative works based on the information, published on the Site;
                    - remove any copyright or other Hayq Art proprietary notices;
                    - mine, hack, spider, or survey the Site;
                    - copy any proprietary information or ideas from the Site;
                    - transmit spam, bulk or unsolicited communications;
                    - pretend to be Hayq Art or someone else, or spoof Hayq Art or someone else's identity or spoof the Site;
                    - forge headers or otherwise manipulate identifiers (including URLs) in order to disguise the origin of any Content, published on the Site;
                    - misrepresent your affiliation with a person or entity;
                    - disrupt the normal flow of dialogue or otherwise act in a manner that negatively affects other Site users' ability to use the Site;
                    - engage in activities that would violate any fiduciary relationship, any applicable local, national or international law, or any regulations having the force of law, including but not limited to attempting to compromise the security of any networked account or site, operating an illegal lottery or gambling operation, stalking, or making threats of harm;
                    - collect or store personal data about other users unless specifically authorized by such users;
                    - collect or store Site data for any other purpose except as explicitly allowed herein.
                    1.3. You agree to prevent any violations of these General Terms, including the licensing terms for the Site. Any violation of these General Terms or Content Policy can lead to account cancellation and a revocation of all licenses.

                </p>
                <p><b>2. Site Ownership</b></p>
                <p>
                    2.1. All information on the Site is copyrighted proprietary material of Hayq Art and (or) Members and may not be copied, reproduced, modified, published, uploaded, posted, transmitted, or distributed in any way, without Hayq Art's prior permission. Except as expressly provided herein, Hayq Art and its suppliers do not grant any express or implied right to you under any patents, registered design, copyrights, trademarks, or trade secret information of Hayq Art.
                    <br />
                    Hayq Art’s logos, slogans trademarks whether registered or unregistered may not be used without Hayq Art’s specific written consent to do so.

                </p>
                <p><b>3. Members</b></p>
                <p>3.1. In consideration of your use of the Site, you agree to:<br /><br />
                    - provide accurate, current, and complete Member account information about you as may be prompted by the registration and/or login form on the Site (the "Registration Data");<br /><br />
                    - maintain the security of your password and identification;<br /><br />
                    - maintain and promptly update the Registration Data, and any information you provide to Hayq Art, to keep it accurate, current and complete;<br /><br />
                    - accept all risks of unauthorized access to information and Registration Data. You have sole responsibility for adequate protection and backup of data and/or equipment used in connection with the Site.<br /><br />
                    3.2. You are responsible for all activity that takes place under your Member user ID. It is your responsibility to maintain the confidentiality of your user ID and password.<br /><br />
                    3.3. By signing up to this Site you agree to register using a valid email address that you are entitled to use.<br /><br />
                    3.4. Hayq Art may provide interactive areas (such as blogs, forums etc.) the content of which are not confidential. Members should exercise the utmost discretion before providing any personal information on these interactive areas of the Site. Users of interactive areas are solely responsible for the content and consequences of anything they post. Hayq Art will have no liability for content and consequences of such postings.<br /><br />
                    3.5. You acknowledge that your uploaded Products will be rated by other Members of the Site. You agree to allow your reputation score to be shown in your account profile.<br /><br />
                    3.6. Hayq Art may terminate or deactivate the membership of any person or entity for any reason at any time without notice. Within 30 days after deactivation of your account, you may submit a motivated request to review Hayq Art’s decision. Your membership will be reinstated if Hayq Art finds your request justified. Deactivation becomes final and the membership is automatically terminated if Hayq Art rejects your request, or, if you do not submit such request, upon expiry of the 30 days term. If your membership is terminated by Hayq Art for a breach of these General Terms, in addition to its other rights at law or in equity, you shall pay a fine to Hayq Art equal to all royalties payable to you hereunder, and Hayq Art shall have the right to set-off such fine against all payable royalties. Membership termination applies to all and any accounts of the person or entity, including accounts created after the membership termination, which will be deactivated without further notice.<br /><br />
                    3.7. This Site may not be accessed or used by any person, entity or group that have been designated for sanctions under the economic sanctions laws, regulations and orders taken by the European Union, the United States and other countries. Your access and use of the Site constitutes your express representation that you are not subject to any trade sanction or embargo, including, but not limited to, by virtue of your designation for sanctions and inclusion on: (i) the Specially Designated Nationals List maintained by the U.S. Department of the Treasury, Office of Foreign Assets Control; (ii) any list of persons, entities and bodies subject to restrictive measures implemented by the European Union; or (iii) any UN Security Council resolution identifying sanctioned or restricted parties. You shall be fully liable to the full extent of the law for any violations of this paragraph, and you hereby agree to defend, indemnify and hold Hayq Art harmless from and against any and all such damages and liability.<br /><br />
                    3.8. Keep all the internal communication with other Hayq Art members inside this Site and do not ask or share contact details.
                </p>
                <p><b>4. Uploading and Posting Content</b></p>
                <p>
                    4.1. You are entirely responsible for all Content that you upload, post or otherwise transmit via the Site. You agree not to upload, post or otherwise transmit via the Site any Content that:<br /><br />
                    - is inaccurate, harmful, obscene, pornographic, defamatory, racist, violent, offensive, harassing, or otherwise objectionable to Hayq Art or other users of the Site;<br /><br />
                    - is printable 3D models, which are replicas or realistic depictions of weapons;<br /><br />
                    - includes unauthorized disclosure of personal information and data. This includes any images of a human being.<br /><br />
                    - violates or infringes anyone's intellectual property rights;<br /><br />
                    - contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;<br /><br />
                    - contains unauthorized third-party commercial advertisements.<br /><br />
                    4.2. Hayq Art reserves the right to edit or remove Content that violates the aforementioned terms.<br /><br />
                    4.3. You agree that all Content posted on the Site forums shall be free to use or disseminate on an unrestricted basis for any purpose, and you grant Hayq Art and all other users of the Site an irrevocable, worldwide, royalty-free, nonexclusive license to use, reproduce, modify, distribute, transmit, display, perform, adapt, resell and publish such Content (including in digital form). You represent and warrant that you have proper authorization for the worldwide transfer and processing of any information that you may provide on the Site.<br /><br />
                    4.4. Hayq Art does not claim ownership of the Content you place on the Site and shall have no obligation of any kind with respect to such Content. Any Content you provide in connection with this Site shall be deemed to be provided on a non-confidential basis.<br /><br />
                </p>
                <p><b>5. Determining and Changing Type of Content</b></p>
                <p>
                    5.1. Hayq Art retains the right, in its sole and absolute discretion, to monitor, edit, prohibit, or remove any Content, at any time, without prior notice, for any reason. This right of Hayq Art shall in no context be interpreted as Hayq Art’s obligation to monitor the accuracy and quality of the Content. <br />
                    5.2. Hayq Art implements and maintains business practices of categorizing Content in various categories (such as “Most Popular”, “Top Sales” etc.) in order to maximize customer satisfaction and sales. By accepting this General Terms the Seller hereby grants the right to Hayq Art to perform such Content categorization upon Hayq Art’s own judgment. <br />
                </p>
                <p><b>6. Privacy and Security</b></p>
                <p>Hayq Art takes privacy very seriously and shall never sell, share, or otherwise disclose any of your personal information to anyone. Information that is collected upon sign up is used only to enhance your experience on the Site and for troubleshooting purposes. The principles of the personal data process are set forth in <a href='#privacy_policy'>Privacy Policy.</a></p><br />
                <p><b>7. Termination of this License</b></p>
                <p>7.1. Hayq Art may unilaterally terminate this license for the use of the Site at any time, without any prior notifications, if you are in breach of any of these terms and conditions of use, as judged in the sole discretion of Hayq Art. Termination of this license is in addition to the other rights and legal remedies available to Hayq Art and those rights are reserved. Upon such termination you must immediately destroy all information that you acquired from the Site.</p>
                <p><b>8. Notifications</b></p>
                <p>All Hayq Art’s notifications shall be sent via email at the address you provided to Hayq Art when you created your account. You hereby agree and acknowledge that Hayq Art shall notify you using either of aforementioned notification means at its own discretion and is not responsible to notify you in any other way.</p>
                <p><b>9. Contests and Promotions</b></p>
                <p>Any contests or promotions created by Hayq Art on the Site may be governed by its own set of official rules, which may have eligibility requirements, such as certain age or geographic area restrictions. By entering or participating in such contests, you will become subject to those official rules. It is your responsibility to read the applicable rules to determine whether or not your participation, registration or entry will be valid or restricted, and to determine the sponsor's requirements.</p>
                <p><b>10. Survival</b></p>
                <p>
                    After the terms of these agreements that expressly or by their nature contemplate performance after the agreement terminates or expires will survive and continue in full force and effect. For example, the provisions protecting confidential information, protecting intellectual property, indemnification, payment of fees and setting forth limitations of liability each, by their nature, contemplate performance or observance after this agreement terminates.
                </p>
                <h3>WARRANTIES AND LIABILITY</h3>
                <p><b>11. General Information</b></p>
                <p>
                    11.1. This section contains important information regarding the liability of Hayq Art that every Member should read carefully and, if necessary, with the appropriate assistance of his legal advisor. <br />
                    11.2. Hayq Art’s Site is a forum for exchange of Product licenses between the Seller and the Buyer or the Designer and the Buyer. Hayq Art merely offers a platform for either Sellers to sell their Product or the Buyers to find a Designer for the desired Product. Thus Hayq Art cannot and shall not provide any warranty and accept any liability for any Product and (or) other Content. The Members are solely and exclusively liable for all Products and other Content that they upload, download and (or) otherwise provide or acquire to or from the Site. As Hayq Art cannot guarantee that the Content you download or access via the Site is problem-free, our liability to you is limited, as described below. You are also agreeing to indemnify us. We cannot provide a warranty for the Product/Content hosted on this site.<br />

                </p>
                <p><b>12. Indemnification</b></p>
                <p>You agree to indemnify, defend and hold harmless Hayq Art, its affiliates, and their respective officers, shareholders, partners, representatives, agents and employees from any and all liability, loss, damages, claim and expense, including reasonable attorney's fees, related to or arising from your violation of General Terms, your use or misuse of the Site and (or) Content, or any infringement of any intellectual property or other right as a result of the of Content or your use or provision of the Content. We reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you, in which you will cooperate with us in asserting any available defenses.</p>
                <p><b>13. Limitation of Liability</b></p>
                <p>In no event shall Hayq Art or any of its affiliates, or their respective officers, shareholders, partners, representatives, agents and employees be liable for any direct, indirect, punitive, special, incidental, exemplary or consequential damages or any damages whatsoever (including without limitation, damages for loss of use, data, information, profits or business interruption) arising out of or in any way related to the use or performance of this Site or any linked web site or to any Content or other material obtained through this Site, or otherwise arising out your inability to use this Site or any decision made or action taken by you in reliance of any information, advice or materials on this Site, whether such damages are based in tort, contract, negligence, strict liability or otherwise, even if Hayq Art has been advised of the possibility of such damages. Your sole and exclusive remedy is to discontinue your access to and use of the Site, even if this remedy fails of its essential purpose. As some jurisdictions do not allow the exclusion or limitation of liability to a certain extent, some of the above exclusions or limitations may not apply to you.</p>
                <p><b>14. No Warranty</b></p>
                <p>
                    14.1. Being only a forum for exchange of Products’ licenses, Hayq Art has no control over the quality or legality of any Content on this Site or the accuracy of any related information. It is your full responsibility to ensure and evaluate the quality and legality of any Content and its use. You agree that Hayq Art shall have no liability for any misrepresentation, incomplete statement or failure to disclose any Content data, or for any claims arising from a transaction resulting from your use of the Site or a business transaction you enter into using the Site.<br />
                    14.2. Hayq Art makes all commercially reasonable efforts to ensure that all material, information and data on this Site are accurate and reliable; however, accuracy cannot be guaranteed.<br />
                    14.3. This Site is provided by Hayq Art on an “as is” basis. Hayq Art makes no warranties with respect to this site, and disclaims all applicable warranties, express or implied, including without limitation all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement for the Site and all Content and any warranties arising from course of dealing or usage in trade. Hayq Art makes no warranty or representation regarding results that may be obtained from use of this site, or use of material, information or data downloaded or otherwise obtained from this Site, or regarding the accuracy or reliability of any information obtained from this Site. Hayq Art does not warrant or guarantee the accuracy, completeness, correctness, timeliness, or usefulness of this Site or any Content or other material, obtained through use of this Site or that use of this site or any product/content on this Site will meet any requirement, be uninterrupted, timely, secure or error-free. Hayq Art shall have no responsibility for the timeliness, deletion, misdelivery or failure to store any user communication.<br />
                    14.4. By accepting this General Terms you acknowledge and agree that your use of this Site and (or) Content is at your own discretion and risk, and that you are solely responsible for any damage that results from the download and use of any Content.<br />
                    14.5. Hayq Art does not warrant or guarantee that Content or other materials and information available through this Site will be free of infections, viruses, worms, Trojan horses or other code that could be harmful to your computer system. You should take all reasonable appropriate precautions against such code and (or) software.<br />
                    14.6. Hayq Art is not liable to users and Members of this Site for any damage resulting from use of this Site or use of Content obtained from this Site, and is not in any way responsible for the conduct of users or Members of this Site or for Content posted or exchanged on this Site.<br />
                    14.7. No advice or information, whether oral or written, obtained by you from Hayq Art or in any manner from this Site shall create any warranty.<br />
                    14.8. Because Content is delivered via the Internet, there may be some circumstances where the delivery of Content is delayed. In no event will Hayq Art be liable for any delays in delivery or performance caused by your failure to provide necessary and accurate information in a timely manner, or caused by circumstances or causes beyond Hayq Art’s reasonable control.<br />
                </p>
                <p><b>15. Third-Party Web Sites</b></p>
                <p>
                    This Site may link to, or be linked to, other websites not maintained by or related to Hayq Art. These links are provided only as a service to our users and Members. Hayq Art is not, directly or indirectly, implying any approval, association, sponsorship, endorsement or affiliation with any linked or linking web site. Hayq Art has not reviewed all third party web sites and is not responsible for their content, accuracy, or policies. If you link to or retrieve any other pages or web sites it is at your own risk.
                </p>
                <p><b>16. Other Terms</b></p>
                <p>
                    16.1. Hayq Art reserves the right at any time to modify, suspend or terminate the services (or any part thereof) provided at the Site, and/or your use of or access to them, with or without notice. Hayq Art may also delete, or bar access to or use of, all related information and files. Hayq Art will not be liable to you or any third-party for any modification, suspension, or termination of the Services, or loss of related information. Hayq Art may amend General Terms, Privacy Policy, Content Policy and Refund Policy at any time without notice, as all terms and conditions will be posted on this URL and should be consulted by you prior to use. <br />
                    16.3. If any provision of this General Terms, Privacy Policy, Content Policy and Refund Policy is held invalid, void, or for any reason unenforceable, that provision shall be severed and the remaining condition will remain in force to the fullest extent provided by law.<br />
                    16.4. Any waiver of any portion of General Terms will be effective only if in writing and signed by an authorized officer of Hayq Art. This is the entire agreement applicable to Content and your use of the Site.<br />
                    16.5. No partnership, joint venture, employer-employee, or franchisor-franchisee relationship is intended or created by General Terms.<br />
                </p>
                <h3>TERMS OF USE REGARDING SELLING 3D MODELS AND 3D PRINTING MODELS</h3>
                <p><b>17. Applicability</b></p>
                <p>
                    17.1 By uploading a Product on the Site and offering them for Sale or free download, the Seller accepts the terms herein. By purchasing or downloading Products on the Site, the Buyer accepts the terms herein. <br />
                    17.2 These terms apply in conjunction with General Terms.

                </p>
                <h3>MEMBER‘S RIGHTS, OBLIGATIONS AND REPRESENTATIONS</h3>
                <p><b>18. Rights, Obligations and Representations of the Seller</b></p>
                <p>
                    18.1. Members may only upload Content they own or otherwise have the right to use and upload. Hayq Art does not own any Content uploaded by Members. <br />
                    18.2. By uploading a Product, the Seller shall be deemed to represent that such Product and use of this Product permitted by General Terms does not infringe the intellectual property of any other party and that the Seller has obtained all necessary model or property releases for use and licensing of the Product. All Members should consult their own legal advisors to determine whether a particular model or property release form and (or) any applicable contract is suitable or necessary for uploading a particular Product or for a particular use of a Product, or whether such use is a fair use. Sellers should understand that licenses of Product without a release attached may be negatively impacted, if a release is deemed necessary by Buyers or their legal advisors.<br />
                    18.3. Sellers may not upload Product or add Content that violates any international or domestic law, statute, ordinance, or regulation. Content cannot be defamatory, libelous, pornographic, obscene, offensive or evocative of racial hatred of any kind, and cannot contain any viruses, spam, malware or other programming routines that detrimentally interfere with computer systems or data.<br />
                    18.4. Sellers should keep a backup of their own uploaded Products at all times. Sellers’ files uploaded to the Site cannot be used as a backup source.<br />
                    18.5. For any Product/Content uploaded to the Site or otherwise submitted to Hayq Art, the Seller grants and represents that he has the authority to grant to Hayq Art  a non-exclusive, worldwide, royalty-free, license in any medium now known or hereinafter invented to:<br />
                    - reproduce, sell, and distribute net proceeds from any sale, in whole or in part on Seller’s behalf;<br />
                    - to publicly perform, publicly display, digitally perform, or transmit for promotional and commercial purposes;<br />
                    - create and use samples of the Product for the purpose of advertising, demonstrating or promoting Seller products or services or those of Hayq Art;<br />
                    - use the Products for the purposes of researching, creating, developing and testing new Hayq Art's tools, technologies or methods related to 3D modeling (including, without limitation, automated recognition, conversion or other processing of 3D models), which may be developed by Hayq Art or with the help of its subcontractors. The Seller explicitly agrees that any such technologies, tools, software, databases, their developments, modifications or improvements shall exclusively belong to Hayq Art to the full extent possible;<br />
                    - use any trademarks, service marks or trade names incorporated in the Product in connection with Seller material;<br />
                    - use the name and likeness of any individuals represented in the Product in connection with Your material.<br />
                    All of the aforementioned license rights shall be used by Hayq Art exclusively to ensure proper administration and promotion of the Site.<br />
                    18.6. Seller retains ownership of the copyrights and all other rights in the Product, uploaded to the Site, subject to the non-exclusive rights granted to the Hayq Art and the Buyer. Seller is free to grant similar rights to others during and after the term of this General Terms.<br />
                    18.7. By uploading a Product to the Site, the Seller represents and warrants that:<br />
                    - the Product is his original work, and contains no copyrighted material of any kind that Seller is not the exclusive owner of, including but not limited to: music and/or synchronization rights, images (moving or still) of any kind, writings of any kind, and model clearances/releases;<br />
                    - Seller has full right and power to enter into and perform this agreement, and have secured all third party consents necessary to enter into this agreement;<br />
                    - the Product does not and will not infringe on any third party's copyright, patent, trademark, trade secret or other proprietary rights, rights of publicity or privacy, or moral rights;<br />
                    - the Product does not and will not violate any law, statute, ordinance or regulation;<br />
                    - the Product is not and will not be defamatory, libelous, pornographic, obscene or evocative of racial hatred of any kind;<br />
                    - the Product does not and will not contain any viruses or other programming routines that detrimentally interfere with computer systems or data;<br />
                    - the Product does not contain any material that, as a condition of use, requires additional license restrictions such as requiring the publication of source code or Product that limits commercial exploitation of Incorporated Product;<br />
                    - all factual assertions that Seller has made and will make are true and complete. Seller agrees to execute and deliver documents toHayq Art and (or) Buyer, upon their reasonable request, that evidence or effectuate their rights under this agreement.<br />
                    18.8.The Seller provides Hayq Art’s partners the same license rights as to Hayq Art in order for his or her Products to be sold on the partner's website.<br />
                </p>
                <p><b>19. Rights, obligations and representations of the Buyer</b></p>
                <p>
                    19.1. The Buyer may not use the Product, downloaded from this site in a manner that infringes the intellectual property of any other party. The Buyer may not reverse engineer any Product and must abide by the terms of the license granted to the Buyer under this General Terms, as well as any additional Seller terms included with the Product. <br />
                    19.2. The Buyer may not perform automated mass download (crawling) technique for gaining access to Products which are offered as a free download. The Buyer may not use Products which are offered as a free download for machine learning or training neural networks purposes.<br />
                    19.3. Following the payment of any applicable license fee for any Product, the Buyer may download the Product from the Site and use it in accordance to the applicable license terms. The Buyer must ensure that any use of the Product is compliant with all applicable laws and regulations and does not infringe upon the rights of third parties, including any intellectual property rights.<br />
                    19.4. Prior to concluding the contract the Buyer shall give express content of waiving his or her 14-day right of withdrawal regarding the digital content (the Product).<br />
                    19.5. When Buyer intends to order and buy products in bulk, Hayq Art may help Buyer manually complete the process of purchase at the Site Required to effect the Sale of Products (e.g. to add Products to shopping cart in bulk).<br />

                </p>
                <h3>TERMS OF LICENSE AGREEMENT BETWEEN THE SELLER, HAYQ ART AND THE BUYER</h3>
                <p><b>20.General Terms of Licensing</b></p>
                <p>
                    20.1. Following the payment of any applicable license fee for Product, the Buyer acquires a license in accordance with the terms and conditions of this section. Any license rights relating to the Product are contingent upon the transfer of money (except for the free Products) from the Buyer to Hayq Art. All license rights terminate immediately and without notice if a sale is reversed for any reason. <br />
                    20.2. Upon uploading the Product to the Site, the Seller shall indicate the terms of license, upon which a respective Product is licensed to the Buyer. If the Seller does not indicate any licensing conditions in his posting, terms of Royalty Free license (see section 21 “Royalty Free License”) shall be applicable.<br />
                    20.3. Certain Products with third party copyrighted or trademarked images, logos, brand names, etc., may require additional licensing, rights, permissions, releases, or clearance for use. It is the sole responsibility of the Buyer and their legal advisor to determine, before purchasing, downloading or using any Product, whether additional licensing, rights, permissions, releases, or clearance are necessary for the intended use of Product. It is the Buyer’s sole responsibility to obtain any licensing, rights, permissions, or clearance. Buyers should understand additional licensing, rights, permissions, releases, or clearance may be relevant for Product, if the intended use involves religious groups or affiliations, pharmaceuticals, health care, tobacco, liquor, adult entertainment, personal hygiene, birth control, or any other area that could be considered sensitive, offensive, or immoral.<br />
                    20.4. Buyers do not own any Product and are only licensed to use it in accordance with terms and conditions of the applicable license. <br />
                    20.5. The license to use the Product is non-exclusive, non-transferable and is granted only to the original Buyer.<br />
                    20.6. Any Product returned to Hayq Art and (or) the Seller in accordance with applicable law for whatever reason must be destroyed by the Buyer immediately. The license to use any Product is revoked at the time Product is returned. Product obtained by means of theft or fraudulent activity of any kind is not granted a license.<br />
                    20.7. By using the Site and (or) accepting this agreement the Seller agrees that any additional end user license agreements, licenses, custom licenses, or Seller requirements inserted into Seller Products in any area outside that explicitly provided by Hayq Art for additional license terms are invalid, void ab initio, and without effect as they relate to those purchases made on the Site.<br />
                    20.8. Nothing in this agreement shall be construed to mean that a Seller may not submit or delete Content from the material submitted by such Seller as part of general maintenance of such Seller’s account.<br />
                </p>
                <p><b><a href="">21. Royalty Free License</a></b></p>
                <p>
                    21.1. Product may not be sold, given, or assigned to another person or entity in the form it is downloaded from the Site or in 3D printed physical form. <br />
                    21.2. The Buyer’s license to Product in this paragraph is strictly limited to Incorporated Product. Any use or republication, including sale or distribution of Product that is not Incorporated Product is strictly prohibited. For illustration, approved distribution or use of Product as Incorporated Product includes, but is not limited to:<br />
                    - as rendered still images or moving images; resold as part of a feature film, broadcast, or stock photography;<br />
                    - as purchased by a game’s creators as part of a game if the Product is contained inside a proprietary format and displays inside the game during play, but not for users to re-package as goods distributed or sold inside a virtual world;<br />
                    - as purchased by creators of virtual worlds, metaverses, applications or platforms (including, but not limited to, social media platforms) if Product is provided to users of such virtual worlds, metaverses, applications or platforms to create embedded content and distribute, modify, publicly perform, or display such embedded content, provided that a) Product is not downloadable by users of such virtual worlds, metaverses, applications or platforms in the form in which it is downloaded from the Site and b) Product is not re-packaged as goods sold inside a virtual world, metaverse, application or platform;<br />
                    - as Product published within a book, poster, t-shirt or other item;<br />
                    - as part of a physical object such as a toy, doll, or model.<br />
                    21.3. If you use any Product in software products (such as video games, simulations, or VR-worlds) you must take all commercially reasonable measures to prevent the end user from gaining access to the Product. Methods of safeguarding the Product include but are not limited to:<br />
                    - using a proprietary disc format such as Xbox 360, Playstation 3, etc.;<br />
                    - using a proprietary Product format;<br />
                    - using a proprietary and/or password protected database or resource file that stores the Product data;<br />
                    - encrypting the Product data.<br />
                    21.4. Without prejudice to sub paragraphs 21.1-21.3, the Seller grants to the Buyer who purchases license rights to Product and uses it solely as Incorporated Product a non-exclusive, worldwide, license in any medium now known or hereinafter invented to:<br />
                    - reproduce, post, promote, license, sell, publicly perform, publicly display, digitally perform, or transmit for promotional and commercial purposes;<br />
                    - use any trademarks, service marks or trade names incorporated in the Product in connection with Seller material;<br />
                    - use the name and likeness of any individuals represented in the Product only in connection with Your material.<br />
                    21.5. Absent a written grant of rights greater than that contained in section 21 “Royalty Free License”, all other rights or sub-divisions of rights generally included in copyright are excluded from this license and remain the property of Seller.<br />
                    21.6. The resale or redistribution by the Buyer of any Product, obtained from the Site is expressly prohibited.<br />

                </p>
                <p><b>22. Process of purchases and payments</b></p>
                <p>
                    22.1. Hayq Art is not a party to the Product purchase agreement but facilitates interaction between Buyer and Seller by providing the marketplace platform available at the Site. Irrespective of any previous relationship, and for the avoidance of doubt, any decision to purchase a Product and arrange for payment of the same shall be done by Buyer alone, and acting in a principal capacity, and in respect of such Hayq Art undertakes no fiduciary, implied, or otherwise further duties or functions on behalf of the Buyer. Receipt of order(s) and payment(s) by Hayq Art shall constitute satisfaction of the Buyer’s obligations with respect to such under these General Terms. <br />
                    22.2. Hayq Art’s role, to the extent it acts on behalf Buyer and/or Seller under these General Terms, if at all, is limited strictly to the functions of accepting and transmitting orders and accepting payment as detailed in the General Terms and do not extend to any duties beyond what is set out therein. Hayq Art undertakes no fiduciary, implied, or otherwise further duties or functions other than those detailed in the General Terms, and both Buyer and Seller agree to such, authorize Hayq Art to act in such a role, and to indemnify Hayq Art for any loss associated therewith accordingly.<br />
                    22.3. Seller authorizes Hayq Art to collect money from the Buyer and distribute payment amounts to Seller, tax authorities, or other entities as the case requires under this agreement and in compliance with legal regulations.<br />
                    22.4. Buyer may choose any payment method supported by the Site for the purchased Products at the time of sale. Buyer may cover up to 30 % of payment by applying Hayq Art Loyalty Credits to the purchase price. Buyer may also make a prepayment to Hayq Art. In this case Buyer will be granted a promotional code with a value of the prepayment and will be able to cover up to 100 % of payment by applying a promotional code. Promotional code can be applied to multiple payments and it will reduce in value after each payment.<br />
                    22.5. Every calendar month by the twentieth (20) day, or the following business day if the twentieth is weekend or holiday, Hayq Art will pay the Seller its royalty payments according to Seller’s reputation level at the moment of purchase (see 4 part of this chapter to find a list of reputation levels).<br />
                    22.6. Hayq Art reserves the right to withhold payments in the following cases:<br />
                    - the amount is below the minimum allowed for a monthly royalty payment (which is $200), unless the Seller has chosen Paypal as his or her transfer method;<br />
                    - Seller’s payments are on hold because previous payment to Seller failed to be received;<br />
                    - Seller has provided inaccurate payment information;<br />
                    - there is violation of Site policies that must be reconciled before any payment is made.<br />
                    The Parties agree that the Reached Royalty Rate defined in this paragraph shall be final; the Reached Royalty Rate shall include all taxes and other charges (including VAT, where applicable) that shall be due in accordance with the applicable laws. The Seller may decide to reduce its Royalty Rate to a certain percent in relation to certain Products for charity purposes. In that case, Hayq Art will dedicate its earnings from the amount of reduction of the Royalty Rates to the charity and pay out directly the amounts to the charity organisations.<br />
                    22.7. Hayq Art provides each Seller detailed sales information including:<br />
                    - Price of the purchase<br />
                    - Buyer information (including country of residence)<br />
                    - Taxes charged<br />
                    - Royalty rate at the moment of purchase<br />
                    22.8. Hayq Art will calculate and pay royalties to Sellers / Designers after withholding taxes on royalties if such obligation is foreseen for Hayq Art in Lithuanian tax Laws.<br />
                    22.9. If a user is from a location where the end buyer pays taxes at the point of purchase (known as Value added tax (VAT), Goods and services tax (GST) or Sales tax) Hayq Art will detect the buyer's location and charge tax which is applied in particular location. Taxes will be deducted from the purchase price and transmitted to local tax authorities. Hayq Art will calculate and pay royalties to Sellers after deduction of taxes.<br />
                    22.10. If a purchase is reversed after you have been paid or if for any reason Hayq Art has already paid to you in excess of the correct royalty payment, you acknowledge that such overpayment is due from you to Hayq Art and authorize Hayq Art to deduct from your future payments. If you discover or become aware of overpayment, you agree to notify Hayq Art of such occurrence and settle it accordingly.<br />
                    22.11. By accepting these Terms and Conditions Designer confirms that he/she acts as a taxable person for the purpose of VAT and agrees that a self-billing invoice for the purchased products shall be issued by Hayq Art under the terms and conditions of applicable laws. However, the parties remain to be solely responsible for determining whether it is required by applicable law to issue any other formal document and pay any other taxes that might apply to them. Hayq Art takes no responsibility for determining the necessity of or for issuing any other formal document (except self-billing invoice) or for determining, remitting, or withholding any taxes applicable to paid fees, except for the VAT, GST or Sales tax and other taxes (withholding tax on royalties) that Hayq Art is obliged to pay in accordance with applicable Law.<br />

                </p>
                <h2 id='privacy_policy'>WHO WE ARE</h2>
                <p>
                Hayq Art (“the Company”, “we”, or “us”) is 3D model marketplaces with models for computer graphics, virtual/augmented reality and gaming, backed by a strong professional designer community. <br />
                We respect your privacy and are committed to protecting it through this Privacy Policy. Like most website operators, Hayq Art collects data about our users and visitors to our website www.hayqart.com (“the Website”). This Privacy Policy should be read in conjunction with our General Terms and Conditions and our<a href='#cookies'>Cookie Policy.</a>
                </p>
                <h2>HOW WE USE YOUR PERSONAL DATA?</h2>
                <p>We will only collect and process your personal data when the law allows us to. Most commonly, we will collect and process your personal data in the following circumstances:</p>
                <ul>
                    <li>Where we need to perform the contract, we are about to enter into or have entered into with you.</li>
                    <li>Where we need to comply with a legal or regulatory obligation.</li>
                    <li>Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests.</li>
                    <li>When you consented to data collection and processing for the particular purpose.</li>
                </ul>
                <h2>WHAT PERSONAL INFORMATION DO WE PROCESS?</h2>
                <p>The personal data we process (collect, use, store and share) about you depends on who you are and how we interact with you: whether you are just a regular visitor to our Website or Guest Buyer, or whether you are a registered member. When we refer in this Privacy Policy to 'personal data' we are referring to data about you (or other living people) from which you could be identified directly or that makes you identifiable indirectly by one or more factors related and specific to you such as your name, your contact details and even your IP address. <br />
                When you register or use the Website you will be asked to "opt in" to our Privacy Policy, General Terms and Conditions, i.e. to confirm that you have read those documents and understand and acknowledge to our use of any personal data that we may collect from you as a user of the Website as set out in this Privacy Policy. 
                </p>
                <p><b>If you are a visitor of our Website</b></p>
                <p>As you visit or interact with our Website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, session logs and other similar technologies. We use cookies to improve our Website, make your browsing experience and our business decisions better. The data we may collect is:</p>
                <ul>
                    <li>IP address</li>
                    <li>Session logs (IP address, login date (month/year), last login country)</li>
                    <li>Your location (country)</li>
                    <li>Your device you are browsing from and the browser you use</li>
                    <li>Referrer website (the website that linked you to our website, if any).</li>
                    <p><b>The purpose of processing </b>– to improve our Website and users experience.</p>
                    <p><b>Legal basis for processing  </b> – the legitimate interest (IT security, marketing) and your consent for processing data for a particular purpose (e.g. the use of cookies).</p>
                </ul>
                <p><b>If you are a Registered User</b></p>
                <p>
                When you register to use our services by creating an account on our Website or connecting your Google account to the Website, we will collect personal data as necessary to register such account:
                </p>
                <ul>
                    <li>Email address</li>
                    <li>IP address</li>
                    <li>Session logs (IP address, login date (month/year), last login country)</li>
                </ul>
                <p><b>The purpose of processing </b>– registration of a Member account.</p>
                <p><b>Legal basis for processing</b> – the performance of the contract between us and you as a Registered User or necessity to take steps at your request before entering into a contract.</p>
                <p><b>Direct Marketing</b></p>
                <p>
                We engage in various marketing activities and try to improve our Website and user experience. We need to know what information on the Website is most interesting for visitors and Members, from which countries they come to our Website, how often they return, which browsers they use, on which devices the Website is browsed, and what is their IP address. We may collect that data by using third party tools such as cookies and other technologies (e.g. Google Analytics or similar ones), which allows us to record and analyze statistical data about use of the Website. Please see our <a href="#cookies">Cookie Policy</a> for further details.
                </p>
                <p><b>Subscribing for the news</b></p>
                <p>Any Website’s visitor or Member can subscribe for Company’s newsletter and receive our news, promotions, sales proposals, etc. by providing his/her email. For this we will process your data:</p>
                <ul><li>Email address</li></ul>
                <p><b>The purpose of processing</b>sending the newsletter.</p>
                <p><b>Legal basis for processing </b>– your consent.</p>
                <p><b>The period of data storage</b>– 5 years after receiving consent or until you withdraw your consent.</p>
                <p>You may withdraw your consent at any time by simply clicking ‘unsubscribe’ link at any email or by sending your withdrawal notice to us by email at <a href='#'>contact@hayqart.com</a></p>
                <p><b>Sending email notifications</b></p>
                <p>If you are a Member (Registered User or Designer) we may send you notifications about our proposals or your activities within your account: notifying you about product and feature announcements, community news, special offers, etc. For this we will process your data:</p>
                <ul><li>Email address</li></ul>
                <p><b>The purpose of processing</b>– sending notifications and provision of services.</p>
                <p><b>Legal basis for processing</b> – the legitimate interest of our Company to improve user experience and the performance of the contract between the parties .</p>
                <p><b>The period of data storage</b>– 2 years after last login.</p>
                <p>You may object to processing of your data for this particular reason or withdraw your consent at any time by simply clicking ‘unsubscribe’ link at any email or by sending your withdrawal notice to us by email: <a href='#'>contact@hayqart.com. </a></p>
                <p><b>Links to other sites</b></p>
                <p>The Website may contain links to other sites, i.e. links leading to websites of our partners or to social media websites. This Policy is applicable only with respect to the Website, but not any other sites, therefore we strongly recommend to review the privacy policies of any websites that you may reach by following hyperlinks presented in this Website. We have no control and no responsibility with regard to any content presented in sites other than the Website.</p>
                <h2>WHO CAN WE SHARE YOUR DATA WITH?</h2>
                <p>
                We put our best efforts to keep your data safe and always require a high level of security and confidentiality from our employees and partners.<br />
                We may share your personal data with our trusted services providers. We use third party service providers to undertake processing operations on our behalf, and this may require us to share your personal data with them when they provide services to us, to you on behalf of us and under our instructions. <br />
                Transaction information data may be accessible to our partners, who provide us with tools for payments.<br />
                We will only provide service providers with the minimum amount of personal data they need to fulfill the services we request, and we stipulate that they protect this information and do not use it for any other purpose. We take these relationships seriously and oblige all of our data processors to sign contracts with us that clearly set out their commitment to respecting your rights as individuals, and their commitments to assisting us to help you exercise your rights as a data subject.<br />
                We engage with Paypal payment provider.<br />
                We may provide your data (or allow access to your data) for our IT support service providers on case by case basis in case of specific incident resolution, e.g. customer service issue, security incident investigation, for accounting service providers, etc.<br />
                We may also disclose your data if required to do so by law or if we believe that such action is necessary to protect and defend the rights, property or personal safety of the Company, the Website or its visitors.<br />

                </p>
                <h2>WHAT ARE YOUR RIGHTS?</h2>
                <p>As a data subject you have a number of rights in relation to your personal data. </p>
                <ul>
                    <li>
                    The right of access – you may request access to the personal data that we hold which relates to you and receive a copy of data that we hold about you.
                    </li>
                    <li>
                    The right to rectification – you may request us to correct personal data that we hold about you which you believe is incorrect or inaccurate and to complete incomplete personal data. We may ask you to verify any new data that you provide to us and may take our own steps to check that the new data you have supplied us with is right. 
                    </li>
                    <li>
                        The right to erasure – you may also ask us to erase personal data if:
                        <p>
                        1.your personal information has been processed unlawfully by us; or <br />
                        2.your personal information is no longer necessary for the purposes for which it was collected by us; or<br />
                        3.you withdraw your consent and where there is no other legal ground for the processing;<br />
                        4.where you object to processing (see below) and there is no overriding legitimate interest for continuing to process your personal data.<br />

                        </p>
                    </li>
                    <li>
                    The right to restrict processing – you may ask us to restrict processing of your personal data in these situations:
                    <p>
                    If you object to processing pending the verification whether the legitimate grounds of the Company override those of you; <br />
                    if you dispute the accuracy of personal data we are processing and want us to verify that data's accuracy;<br />
                    where it has been established that our use of the data is unlawful but you do not want us to erase it;<br />
                    where we no longer need to process your personal data (and would otherwise dispose of it) but you wish for us to continue storing it in order to enable you to establish, exercise or defend legal claims.<br />

                    </p>
                    </li>
                    <li>
                    The right to data portability – you can ask us to provide you or transmit those data directly to another controller of your choice, where technically feasible, certain personal data that we hold about you in a structured, commonly used machine-readable format. However, you must keep in mind that you may exercise the right of data portability only on data that is processed based on your consent or on a performance of a contract between you and us and that is being processed by automated means. We can guarantee only transferring data to you in such occasions and can not be responsible with technical compatibility of other party systems where data transfer is requested.
                    </li>
                    <li>
                    The right to withdraw consent regarding processing of personal data – you may withdraw your given consent for processing of personal data for particular purposes at any time by informing us at email <a href='#'>contact@hayqart.com</a> or following the procedure that was specified before obtaining your consent.

                    </li>
                    <li>
                    The right to object - you can object to our processing your personal data under certain circumstances including where processing takes place in line with the Company’s legitimate business interests as set out above. Where you object to processing, we must stop processing your personal data unless we can show that our legitimate ground for processing of your personal data overrides your interests or where we need to process the data to establish, exercise or defend legal claims.
                    </li>
                    <li>
                    The right to object to automated decision making and profiling – you have the right to be informed about the existence of any automated decision making and profiling of your personal data and where appropriate, be provided with meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing that affects you.
                    </li>
                </ul>
                <h2>
                UPDATES OF THE PRIVACY POLICY
                </h2>
                <p>
                We may change this Privacy Policy from time to time based on changes to applicable laws and regulations or other requirements applicable to us, changes in technology, or changes to our business. Any changes we make to the Privacy Policy in the future will be posted on our Website. We may also notify you directly via email about the changes and the effective date of the updated Privacy Policy if changes affect how we process personal data. If you will continue to use our services after the effective date will mean that you have accepted the changes. <br />
                This Privacy Policy was last updated in April 2022.

                </p>
                <h2 id='content_policy'>
                Content Policy
                </h2>
                <p>
                Hayq Art is a marketplace for 3D models and later will become a community for 3D designers.  <br />
                As defined in our General Terms and Conditions, by uploading a 3D model the user warrants that he or she holds intellectual property rights to the content. Since our members are selling or buying intellectual property themselves, we do not tolerate copyright infringement or violations of intellectual property rights on hayqart.com. We also do not support adult or violent content on Hayq Art in order to keep it a friendly place for everyone.<br />
                If you come across content of adult, violent or otherwise illicit nature, please report to Hayq Art at the address provided below.<br />
                If you have any questions or comments about intellectual property rights or the content policy at Hayq Art, please contact us at conctact@hayqart.com<br />

                </p>
                <h2 id='cookies'>
                What are cookies?
                </h2>
                <p>
                A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.
                </p>
                <h2>How do we use cookies?</h2>
                <p>
                Our Website uses cookies to collect your IP address and browsing information, such as the websites you have visited or time you have spent on each page and allow the Website to remember your system and preferences. <br />
                Other cookies allow us to track Website traffic and users’ interaction with the Website – we use this data to analyze visitors’ behavior and to improve the visitors’ experience. <br />
                Cookies allow us to:
                </p>
                <ul>
                    <li>
                    give you a better user experience when you visit our websites;
                    </li>
                    <li>
                    allow you to set personal preferences;
                    </li>
                    <li>
                    create statistical information about the use of our websites;
                    </li>
                    <li>
                    and to measure the effectiveness of advertising.
                    </li>
                </ul>
                <p>We use the following categories of cookies on our websites:</p>
                <p><b>Performance Cookies</b></p>
                <p>These cookies collect anonymous statistical information on how people use our Website. For example, performance cookies may help us understand how users browse or use our website and highlight areas that are used the most.</p>
                <p><b>Targeted advertising cookies</b></p>
                <p>
                These cookies collect information about your browsing habits. They are used to make advertising more relevant to you and your interests. We may also use these cookies to limit the number of times you see an advert, and to estimate how the advertisements on our websites reach our users. The cookies are usually placed by third party advertising networks to create information about your interests through your online behavior.
                </p>
                <p><b>Other third party cookies</b></p>
                <p>We also may have social media cookies on our websites. These cookies allow you to share what you’ve been doing on the website on social media, such as Facebook, Twitter, LinkedIn, Google Plus or Pinterest. We are not responsible for such cookies. Please read the privacy policies of these third parties carefully.</p>
                <p><b>How long are cookies retained on my device?</b></p>
                <p>Persistent cookies are stored on your hard drive until you delete them or they reach their expiry date. We will not store cookie information from persistent cookies for longer than a maximum of 2 years.</p>
                <p>
                Cookies are placed on your device only if you provide your consent, unless cookies are required for strictly technical functioning of the Website. However, note that if you do not consent to the use of cookies, certain functions of the Website may not function properly or may not function at all. <br />
                Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.<br />
                To find more information about the use of your personal data, recipients of personal data, data storage, data security and your rights as the data subject, please read our Privacy Policy.<br />

                </p>
                <h2 id='refund_policy'>Refund policy</h2>
                <p>
                    As a buyer, we want to make sure you are happy with each and every purchase you make. The request should be submitted to contact@hayqart.com within 7 days after the purchase and should be based on one of the reasons identified below:
                </p>
                <ul>
                    <li>
                    The product does not match it's description/preview images in a significant manner
                    </li>
                    <li>
                    Crucial model parts, details, textures or files are missing (not applicable when issues are caused by exchange format limitations)                    </li>
                    <li>
                    The model is mistaken for a physical/tangible object and the digital file has not been downloaded.
                    </li>
                </ul>
                <p>
                When requesting a refund, please, indicate the <b> purchase order number, the problem encountered, software and file formats used.</b> After receiving a refund request, Hayq Art commits to process the request within 3 business days. In case of a refund, you are legally bound to destroy all copies of purchased product files without the right to keep or use them in the future.
                </p>
            </div>
        </div>
    )
}

export default Privacy;

