import './Admin.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc,deleteDoc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import Edit from './Edit'
import nkar from '../../assets/img/2d.png'
import Uploader from './Uploader/Uploader'

const Admin = (props) => {

    const { db, storage, store } = useContext(Context)
    const portfolio = store.getState().portfolio.portfolio
    const [divId,setDivId] = useState('')
    const [title, setTitle] = useState('')
    const [mainTitle, setMainTitle] = useState('')
    const [paragraph, setParagraph] = useState('')
    const [file, setFile] = useState(null)
    const [file2, setFile2] = useState([])
    const [imageUrl, setImageUrl] = useState(null)
    const [imageUrl2, setImageUrl2] = useState([])
    const [category, setCategory] = useState('2d')
    const [uploaded,setUploaded] = useState(null)
    const images = []
    const date = Date.now()
    const [loginData,setLoginData] = useState(false)
    const [loginInput,setLoginInput] = useState('')
    const [queryValue,setQueryValue] = useState('')
    const [path,setPath] = useState('')
    const [editBox,setEditBox] = useState(false)
    const [videoLink,setVideoLink] = useState('')
    const [video,setVideo] = useState(false)
    const [price,setPrice] = useState('')
    const [modelsFile,setModelsFile] = useState(null)
    const [modelsUrl,setModelsUrl] = useState(null)

    let ymdDate = date => date.toISOString().slice(0,19).replace('T', ' ')
    const portfoloioSorted = portfolio.sort((a, b) => b.date.localeCompare(a.date))
    console.log('reversed list' + portfoloioSorted)
    const searchPortfolioSorted = portfoloioSorted.filter(blog => {
        if(queryValue === ''){
            return blog
        }else if(blog.name.toLowerCase().includes(queryValue.toLowerCase())){
            return blog
        }
    })
    let stringType = '1'
    console.log(Number(stringType))


    const loginToAdmin = () => {
        if(loginInput === '1a@3g7jfasqA'){
            setLoginData(true)
        }
    }

    

    const addPost = () => {
        if (!file) return
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (modelsFile) {
            const storageFile = ref(storage, '/models/' + modelsFile.name)
            uploadBytes(storageFile, modelsFile).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageFile,modelsFile.name)
                .then(url => {
                    setModelsUrl(url)
                    console.log('model url -' + modelsUrl)
                })
        }
        if (file) {
            getDownloadURL(storageRef,file.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }
        

        if (file2 !== []) {
            file2.map(image => {
                const storageRef2 = ref(storage, '/images/' + image.name)
                uploadBytes(storageRef2, image).then(response => {
                    console.log('file2 uploaded')
                })
                getDownloadURL(storageRef2,image.name)
                    .then(urls => {
                        setImageUrl2((prevState) => [...prevState, urls])
                        console.log('file 2 url -' + imageUrl2)
                    })
            })

        }


        
            if(videoLink === '' && imageUrl !== null && imageUrl2 !== null && path !== ''){
                setDoc(doc(db, 'portfolio', date + 'id'), {
                    id: date + 'id',
                    name: title,
                    paragraph: paragraph,
                    mainTitle:mainTitle,
                    type: category,
                    modelUrl:modelsUrl,
                    img: imageUrl,
                    image2: imageUrl2,
                    price:price,
                    path: path,
                    
                    date:ymdDate(new Date())
                }).then(() => {
                    setUploaded(true)
                })
                
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }else if(videoLink !== '' && imageUrl !== null && imageUrl2 === null && path !== ''){
                setDoc(doc(db, 'portfolio', date + 'id'), {
                    id: date + 'id',
                    name: title,
                    paragraph: paragraph,
                    mainTitle:mainTitle,
                    type: category,
                    modelUrl:modelsUrl,
                    img: imageUrl,
                    video:videoLink,
                    price:price,
                    path: path,
                    date:ymdDate(new Date())
                }).then(() => {
                    setUploaded(true)
                })
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }else{
                setUploaded(false)
            }





    }
    console.log(file2)
    const takeFiles = (e) => {
        for(let i = 0;i < e.target.files.length;i++){
            const newImage = e.target.files[i]
            newImage["id"] = Math.random()
            setFile2((prevState) =>[...prevState,newImage])
            console.log(file2)
        }
    }


    return (
        <div>
            {loginData === true
            ?
            <div className="main_admin">
            <div className='admin_inputss'>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="URL" value={path} onChange={(e) => setPath(e.target.value)} />
                </div>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="Main title" value={mainTitle} onChange={(e) => setMainTitle(e.target.value)} />
                </div>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className='admin_input1'>
                    <input type={"text"} placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
                <textarea rows={'19'} placeholder="Description" value={paragraph} onChange={(e) => setParagraph(e.target.value)} className="admin_input1 marginTop"></textarea>
                <div className="centered_div">
                    <div className='flex_direction_class'>

                            <div className='admin_file_input'>
                                <label className='color_white'>Main picture</label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} />
                            </div>
                            <div className=''>
                                <label className='color_white '>Upload video</label><br />
                                <input type={'checkbox'} onClick={() => setVideo(!video)} className='upload_vid_input' />
                            </div>
                            {video 
                            ?
                            <div className='admin_input1'>
                                <input type={"text"} placeholder="Write video URL" value={videoLink} onChange={(e) => setVideoLink(e.target.value)} />
                            </div>
                            :
                            <form>
                                <div className='admin_file_input'>
                                    <label className='second_photo_label color_white'>Article pictures (1200 x 800)</label>
                                    <input type='file' onChange={takeFiles} multiple />
                                </div>
                            </form>
                            }
                            <div className='admin_file_input'>
                                <label className='second_photo_label color_white'>Files</label>
                                <input type='file' onChange={(e) => setModelsFile(e.target.files[0])} />
                                <span>Model url - {modelsUrl}</span>
                            </div>

                            <div className='select_category'>
                                <select className='' value={category} onChange={e => setCategory(e.target.value)}>
                                    <option value={'2d'} onChange={e => setCategory(e.target)}>2D</option>
                                    <option value={'smm'} onChange={e => setCategory(e.target)}>3D</option>
                                    <option value={'gd'} onChange={e => setCategory(e.target.value)}>Grapich design</option>
                                </select>
                            </div>
                            <div className='create_admin_post'>
                                <button onClick={addPost}>Create Post</button>
                            </div>
                            {uploaded  
                            ?
                                <div>
                                    <span className='succses_upload'>Succsesfully uploaded</span>
                                </div>
                            :
                                <div>
                                    <span className='reject_upload'>Try again dont uploaded</span>
                                </div>
                            }
                        <div className='bottom_content'>
                            <input placeholder='Search portfolio' value={queryValue} onChange={(e) => setQueryValue(e.target.value)} />
                            {searchPortfolioSorted.map(item => (
                                <>
                                    <div key={item.id} id={item.id} onClick={(e) => setDivId(e.currentTarget.id)} >
                                        
                                        <h3 className='color_white'>{item.name}</h3>
                                        <img src={item.img} className='content____img' />
                                        <p className='color_white'>{item.paragraph}</p>
                                        
                                        <div className='content_buttons'>
                                            <button className='delete_button' onClick={() => {
                                                setTimeout(() => {
                                                    deleteDoc(doc(db,'portfolio', divId))
                                                }, 2000)
                                            }}>Delete</button>
                                            <button className='edit_button' onClick={() => setEditBox(!editBox)}>Edit</button>
                                        </div>
                                        {editBox === true && item.id === divId
                                        ?
                                        <div>
                                            <Edit id={divId} category={category} setEditBox={setEditBox} data={searchPortfolioSorted} />
                                        </div> 
                                        :
                                        null
                                        }
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            :
            <div className='login_block'>
                <div className='login_block_flex'>
                    <h1>Login to admin</h1>
                    <input value={loginInput} onChange={(e) => setLoginInput(e.target.value)} type='password' />
                    <button onClick={loginToAdmin} className='login_button'>Login</button>
                </div>
            </div>
            }
            

        </div>
    )
}



export default Admin