import React, { createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import {initializeApp} from 'firebase/app'
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { Provider } from 'react-redux';



const firebaseConfig = {
  apiKey: "AIzaSyCcsNZeOBIFR0WbSnMgszM00boPz1gMGOM",
  authDomain: "admin-panel-hayqart.firebaseapp.com",
  projectId: "admin-panel-hayqart",
  storageBucket: "admin-panel-hayqart.appspot.com",
  messagingSenderId: "210985931786",
  appId: "1:210985931786:web:29a13129bbb8b248133bf3"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app)
const auth = getAuth(app)

export const Context = createContext(null)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Context.Provider value={{
        db,
        storage,
        store,
        auth,
      }}>
          <App />
      </Context.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
